import { Component, OnInit, ViewChild, Input, TemplateRef, ElementRef } from '@angular/core';
//import { ModalDirective } from 'ngx-bootstrap/modal';
import { fadeInOut } from '../../../services/animations';
//import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { PrinterService } from '../../../services/printer.service';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { MySQLDocument } from '../../../models/mysqldocument.model';
import { SalesOrderList } from '../../../models/salesorder-list.model';
import { Branch } from '../../../models/branch.model';
import { InvoiceBarcode } from '../../../models/invoice-barcode.model';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { BarcodeReference } from '../../../models/barcode-reference.model';
import { ConfigurationService } from '../../../services/configuration.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BankImport } from '../../../models/bank-import.model';
import { User } from '../../../models/user.model';
import { SalesOrderHeader } from '../../../models/salesorder-header.model';
import { forEach } from '@angular/router/src/utils/collection';
import { BankProcess } from '../../../models/bank-process.model';
import { SysproBank } from '../../../models/syspro-bank.model';
import { SysproPaymentHeader } from '../../../models/syspro-payment-header.model';
import { SysproPaymentDetail } from '../../../models/syspro-payment-detail.model';
import { CoreAudit } from '../../../models/core-audit.model';
import { SysproEFTHeader } from '../../../models/syspro-eft-file-header.model';
import { SysproEFTDetail } from '../../../models/syspro-eft-file-detail.model';



@Component({
  selector: 'bank-export',
  templateUrl: './bank-export.component.html',
  styleUrls: ['./bank-export.component.scss']
})
export class BankExportComponent implements OnInit {


  @ViewChild('bank')
  private bank;

  @ViewChild('bankList')
  private bankList;

  @ViewChild('bankSelector')
  private bankSelector;

  sysproBankList: SysproBank[] = [];

  selectedBank: string;

  @ViewChild('batchNumber')
  private batchNumber;

  @ViewChild('batchNumberList')
  private batchNumberList;

  @ViewChild('batchNumberListSelector')
  private batchNumberListSelector;

  selectedBatchNumber: string;


  @ViewChild('batchDescriptionList')
  private batchDescriptionList;

  @ViewChild('batchDescriptionListSelector')
  private batchDescriptionListSelector;

  @ViewChild('paymentHeader')
  private paymentHeader;

  @ViewChild('paymentHeaderList')
  private paymentHeaderList;

  @ViewChild('paymentHeaderSelector')
  private paymentHeaderSelector;

  syspropaymentHeaderList: SysproPaymentHeader[] = [];

  syspropaymentDetailList: SysproPaymentDetail[] = [];
  syspropaymentDetailListCache: SysproPaymentDetail[] = [];

  sysprorejectedDetailList: SysproPaymentDetail[] = [];
  sysprorejectedDetailListCache: SysproPaymentDetail[] = [];

  selectedPaymentNumber: string;


  fileHeader: SysproEFTHeader;
  fileDetails: SysproEFTDetail[] = [];

  batchDescription: string;
  totalValue: number;
  totalExceedValue: number;

  @ViewChild('fileInput')
  fileInput: ElementRef;

  @ViewChild('periodListSelector')
  private periodListSelector;
  allPeriods: Branch[] = [];


  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;


  columns: any[] = [];
  resultcolumns: any[] = [];

  stagedRows: BankImport[] = [];
  stagedRowsCache: BankImport[] = [];

  resultRows: BankImport[] = [];
  resultRowsCache: BankImport[] = [];

  successcount: number = 0;
  processcount: number = 0;
  isPosting: boolean;
  SysproUser: User;

  rows: BankImport[] = [];
  rowsCache: BankImport[];

  @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;


  @ViewChild('statusTemplate')
  statusTemplate: TemplateRef<any>;

  selectedFileName: string;
  selectedCustomer: string;
  selectedCompany: string;
  selectedDocumentType: string;

  loadingIndicator: boolean = false;

  selected: { startDate: Moment, endDate: Moment };
  show: boolean;

  periods: [{ "Current": "Current", "Date": "June 2021" },
    { "Previous": "Previous", "Date": "May 2021" }  ];

  ranges = {
    //'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  periodRanges = {
    'Current': [moment().startOf('month'), moment().endOf('month')],
    'Previous': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  selectedPeriod: string;

  newDebtorsFile = new FormData();

  @ViewChild('uploadModal')
  uploadModal: ModalDirective;
  arrayBuffer: any;
    index: any;
    complete: boolean;
    currentFileName: string;
    skipCount: number;
    posintgCount: number;
    failedCount: number;
    gridHeigt: string;
  fileName: string;
  branchCode: string;
    batchTotal: number;
    maxLineValue: any;
    totalRejected: number;
  maxBatchValue: any;
    formattedAmount: any;
    src: string;
    newFileName: string;
  

  constructor(private router: Router, private alertService: AlertService, private fowkesService: FowkesOnlineService, private configurations: ConfigurationService,
    private sysproService: SysproService, private route: ActivatedRoute, private accountService: AccountService, private printerService: PrinterService) {

  }

  ngOnInit() {

    this.resetForNextBatch();  

    this.selected = {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day')
    }

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    this.gridHeigt = "height: 300px;";


  }

  setDataGrids() {
    this.columns = [
      { prop: 'supplier', name: 'Supplier', width: 100 },
      { prop: 'supplierName', name: 'Name', width: 250 },
      { prop: 'supplierBank', name: 'Bank', width: 150 },
      { prop: 'supplierBranch', name: 'Branch', width: 80 },
      { prop: 'supplierRefNo', name: 'Reference', width: 150 },
      { prop: 'supplierPayValue', name: 'Amount', width: 100 },
      { prop: 'message', name: 'Message', width: 250 }
    ];

   
  }

  resetForNextBatch() {
    this.newFileName = "";
    //window.open("file:///C:\DebtorsFiles", '_blank', '');
    this.selectedCompany = (this.application == "MaxArcusOnline" ? "M" : "F");
    this.selectedBank = "ST";
    this.selectedBatchNumber = "001";
    this.maxBatchValue = (12000000.00).toFixed(2);
    this.maxLineValue = (2000000.00).toFixed(2);
    this.branchCode = "051001";

    this.sysproService.getSysproBankList().subscribe(x => this.onBanksLoadSuccessful(x), error => this.onBanksFailed(error));
    this.sysproService.getSysproPaymentRunHeader().subscribe(x => this.onPayRunHeaderLoadSuccessful(x), error => this.onBanksFailed(error));


    this.selectedPeriod = "C";
    this.complete = false;
    this.stagedRows = [...[]];
    this.stagedRowsCache = [...[]];
    this.resultRows = [...[]];
    this.resultRowsCache = [...[]];
    this.currentFileName = "";
    this.fileName = "";
    this.newDebtorsFile = new FormData();
    this.fileInput.nativeElement.value = "";
    

    this.setDataGrids();
  }

  formatToCurrency = amount => {
    return  amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  currencyPipe(value: any, ...args: any[]) {
    let updated = value.toFixed(2);
    updated += '';
    let x = updated.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
    //return value.toFixed(2);
  }

    onPayRunHeaderLoadSuccessful(x: SysproPaymentHeader[]): void {
      this.syspropaymentHeaderList = x;

      setTimeout(() => {
        if (this.paymentHeaderSelector) {
          this.paymentHeaderSelector.refresh();
        }
      });
  }

  onBanksLoadSuccessful(x: SysproBank[]): void {
    this.sysproBankList = x;

    this.selectedBank = "ST";
    setTimeout(() => {
      if (this.bankSelector) {
        this.bankSelector.refresh();
      }
    });

  }
  onBanksFailed(error: any): void {

  }


  viewPaymentDetailsForNumber() {
    if (this.selectedPaymentNumber) {

      this.sysproService.getSysproPaymentRunDetails(this.selectedPaymentNumber).subscribe(x => this.onPayRunDetailLoadSuccessful(x), error => this.onBanksFailed(error));
    }
     
  }
  onPayRunDetailLoadSuccessful(x: SysproPaymentDetail[]): void {

    this.totalValue = 0;
    this.totalRejected = 0;

    this.syspropaymentDetailListCache = x;
    this.batchTotal = this.syspropaymentHeaderList.filter(h => h.paymentNumber == this.selectedPaymentNumber)[0].netPayValue;
    var successList = [];
    var rejectList = [];
    for (var row of this.syspropaymentDetailListCache) {
      var newRow = new SysproPaymentDetail();
      newRow = row;
      if (row.supplierBank.trim() == "" || row.supplierBranch.trim() == "") {
        newRow.message = "Supplier Bank Info Missing";
        rejectList.push(newRow);
        this.totalRejected += row.supplierPayValue;
      }
      else {
        if (row.supplierPayValue > parseFloat(this.maxLineValue)) {
          newRow.message = "Payment is more than the maximum line value";
          rejectList.push(newRow);
          this.totalRejected += row.supplierPayValue;
        }
        else {
          newRow.message = "";
          successList.push(newRow);
          this.totalValue += row.supplierPayValue;
        }
      }
    }

    this.syspropaymentDetailList = [...successList];
    this.sysprorejectedDetailList = [...rejectList];
    if (this.totalValue > parseFloat(this.maxBatchValue) ) {

      this.alertService.showMessage('Error', "Total value exceeds batch limit" , MessageSeverity.error);

    }

  }

  runEFTExtract() {

    if (this.validated()) {
      let currentDate: Date = new Date(this.selected.startDate.toString());
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let day = ("0" + currentDate.getDate()).slice(-2);
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
      this.alertService.startLoadingMessage('Creating Payment File...');
      this.fileHeader = new SysproEFTHeader();
      this.fileHeader.StrHomeAccount = this.sysproBankList.filter(r => r.bank == this.selectedBank)[0].bankAccount;
      this.fileHeader.strGlobRef = "FOWKES BROTHERS PTY LTD";
      this.fileHeader.strBatchDesc = this.batchDescription;
      this.fileHeader.dteActDate = currentDate;
      this.fileHeader.decTotal = this.totalValue;
      this.fileDetails = [];
      let tempTable = this.syspropaymentDetailList.filter(s => s.supplierBank.trim() != '');
      for (var row of tempTable) {
        let addRow = new SysproEFTDetail();
        addRow.strSupplier = row.supplier;
        addRow.strSuppName = row.supplierName;
        addRow.strSupplierBank = row.supplierBank;
        addRow.strSupplierBranch = row.supplierBranch;
        addRow.strSupplierRefNo = row.supplierRefNo;
        addRow.decSuppPayValue = row.supplierPayValue;
        this.fileDetails.push(addRow);
      }

      const postObject = {
        "user": this.accountService.currentUser, "header": this.fileHeader, "detail": this.fileDetails
      };

      this.printerService.postEFTToFile(this.selectedPaymentNumber, postObject).subscribe(x => this.onPrintSuccesful(x), error => this.onFileFailed(error));
    }


  }

  UpdateSyspro() {

    this.printerService.updateSysproPayRun(this.selectedPaymentNumber).subscribe(x => this.onSysproUpdateSuccess(x), error => this.onBanksFailed(error));
  }
    onSysproUpdateSuccess(x: any): void {
      this.isPosting = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Success', ` Syspro Updated Successfully ` + x.actionOutput, MessageSeverity.success);
    }

  validated(): boolean {
    let error = "";

    error = (this.selectedBank === 'undefined' || this.selectedBank == null || this.selectedBank == "") ? "Please select a Bank" : "";
    error += (this.selectedBatchNumber === 'undefined' || this.selectedBatchNumber == null || this.selectedBatchNumber == "") ? "Please select a Batch Number" : "";
    error += (this.batchDescription === 'undefined' || this.batchDescription == null || this.batchDescription.trim() == "") ? "Please enter a four digit batch number" : "";
    
    if (error.length > 1) {
      this.isPosting = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage('Error', error, MessageSeverity.error);
      return false;
    }
    else {
      return true;
    }
  }


  onFileFailed(error: any): void {
    this.isPosting = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('File Error', 'The below errors occured whilst creating your file:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  onPrintSuccesful(x: CoreAudit): void {
    this.isPosting = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Success', ` File Created Successfully ` + x.actionOutput, MessageSeverity.success);
    var result = x;
    this.newFileName = x.actionOutput;
  //  this.getFile(this.newFileName);
   //   this.printerService.getFileStream(encodeURIComponent(x.actionOutput)).subscribe(x => this.onFileSuccessful(x, false), error => this.onDataLoadFailed(error));
    this.complete = true;
  }

  getFile(downloadPath) {
    console.log(downloadPath);
    window.open("\\" + downloadPath, '_blank', '');
  }

  onFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "text/plain" });
   
    this.src = url;
    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
      console.log(x);

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //  window.navigator.msSaveOrOpenBlob(newBlob);
      //  return;
      //}

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;

      link.download = this.newFileName + ".txt";
      link.click();
      // this is necessary as link.click() does not work on the latest firefox
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  onHistoryLoadSuccesfull(x: BankImport[]): void {
    if (x && x.length >= 1) {
      this.resultRowsCache = x;
      this.resultRows = [...this.resultRowsCache];
    }
  }


  ngAfterViewInit() {

    this.setDataGrids();
  }

  openUploadModal() {
    if (!this.uploadModal) {
      setTimeout(() => {
        if (this.uploadModal) {
          this.uploadModal.show();
        }
      });
    }
    else {
      this.uploadModal.show();
    }
  }


  uploadModalHide() {
    this.uploadModal.hide();
  }

  onNewFile(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = file.name.replace(/\s/g, "") + "-" + this.selectedCompany + this.getFormattedTime() + this.selectedPeriod;
        this.newDebtorsFile.append('file', file);
      }
  }

  startImport() {
    if (this.newDebtorsFile.has('file')) {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.uploadModalHide();
      this.stagedRows = null;
      this.stagedRowsCache = null;
      this.successcount = 0;
      this.processcount = 0;
      
      //var newFileName = this.selectedCompany + this.getFormattedTime() + this.selectedPeriod;
      this.sysproService.uploadBankFile(this.newDebtorsFile, this.selectedCompany, this.fileName, this.selectedBank, this.selectedPeriod).subscribe(
        x => this.onStagingFileSuccess(x, this.fileName), error => this.onDataLoadFailed(error));
    }
  }

  getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    var m = today.getMonth() + 1;
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + "-" + m + "-" + d + "T" + h + mi + s + "-";
  }

  onStagingFileSuccess(x: any, fileName: string) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.currentFileName = fileName;
    if (x && x[0] && x.length > 0) {
      const details = x;
      details.forEach((line, index, details) => {
        (<any>line).index = index + 1;
      });

      this.stagedRowsCache = [...details];
      this.stagedRows = this.stagedRowsCache;
      this.newDebtorsFile = new FormData();
    }
    else {
      this.alertService.showStickyMessage('No results', 'No results found, try adjusting your search criteria',
        MessageSeverity.warn);
    }
  }


  get application(): string {
    return this.configurations.applicationName;
  }

  onSearchChanged(value: string) {
    if (value != "") {
      this.stagedRows = this.stagedRowsCache.filter(r => Utilities.searchArray(value, false, r.status, r.bankReference, r.customer, r.customerName, r.paymentDate, r.paymentValue));
    }
    else {
      this.stagedRows = this.stagedRowsCache;
    }
  }

  postRecord(row: any) {

    if (!this.isPosting) {
      if (this.validatePost(row)) {
        this.isPosting = true;
        this.index = row.index;
        if (!this.SysproUser || this.SysproUser.sysproSessionId) {
          this.alertService.startLoadingMessage('Loggin into Syspro...');
          //Check SYSPRO User
          this.sysproService.GetSysproSessionId(this.accountService.currentUser).subscribe(x => this.onSingleSessionCreatedSuccesful(x, row), error => this.onSessionFailed(error));

        }
        else {
          this.onSingleSessionCreatedSuccesful(this.SysproUser, row);
        }
      }
    }
  }

  deleteRecord(row: any) {
    //Update local record
    row.status = "SKIPPED";
    row.message = "";
    this.sysproService.updateBankImportLine(row, row.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));
  }

  onSingleSessionCreatedSuccesful(x: User, row: any): void {
    this.alertService.stopLoadingMessage();
    this.alertService.startLoadingMessage('Posting Payment...');
    this.SysproUser = x;
    if (this.SysproUser.sysproSessionId) {
      let batchId = this.selectedCompany + this.getFormattedTime() + this.selectedPeriod; 
      row.status = "PROCESSING";
      row.batchId = batchId;
      row.userName = this.SysproUser.sysproOperator;
      //Update local record
      this.sysproService.updateBankImportLine(row, row.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));

      const detail = row;
      if (detail) {
        const postObject = {
          "user": this.SysproUser, "detail": detail
        };
        this.sysproService.postSinglePaymentToSyspro(postObject).subscribe(x => this.onSingleArstpyPOSTSuccesful(x), error => this.onSingleArstpyPOSTFailed(row, error));
      }
    }
    else {
      this.alertService.showMessage('Warning', `You have not logged into Syspro`, MessageSeverity.warn);
      this.isPosting = false;
    }
  }

  onSingleArstpyPOSTSuccesful(x: BankImport): void {

    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Payments posted to syspro successfully`, MessageSeverity.success);
    this.loadingIndicator = false;
    if (x && x.id >= 1) {
      //Update local record
      let index = this.resultRows.findIndex(r => r.id === x.id)
      let rowToUpdate = this.resultRows[index];
      rowToUpdate = x;
      this.resultRows = [...this.resultRows]; 
      this.sysproService.updateBankImportLine(x, x.id).subscribe(x => { }, error => this.onUpdateFailed(error));
    }

    this.isPosting = false;
    this.complete = true;
  }

  onSingleArstpyPOSTFailed(posteRow: BankImport, error: any): void {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Error', 'The below errors occured whilst posting payments:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    //Update local record
    this.sysproService.updateBankImportLine(posteRow, posteRow.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));

    this.isPosting = false;
    this.complete = true;
  }

  postAll() {
    if (!this.isPosting) {
      if (this.validatePost()) {
        this.isPosting = true;
        //Check SYSPRO User
        this.alertService.startLoadingMessage('Loggin into Syspro...');
        this.sysproService.GetSysproSessionId(this.accountService.currentUser, false, this.selectedCompany).subscribe(x => this.onsessionCreatedSuccesful(x), error => this.onSessionFailed(error));
      }
    }    
  }


  onsessionCreatedSuccesful(x: User): void {
    this.alertService.stopLoadingMessage();
    this.SysproUser = x;
    if (this.SysproUser.sysproSessionId) {

      this.index = 0;
      this.skipCount = 0;
      this.posintgCount = 0;
      this.failedCount = 0;
      const rowsToProcess = this.stagedRows.filter(x => x.status != "ERROR");
      this.processcount = rowsToProcess.length;
      let batchId = this.selectedCompany + this.getFormattedTime() + this.selectedPeriod;

      this.alertService.startLoadingMessage('Posting ' + this.processcount + ' Payments...');    

      //Update Local Records
      for (var row of rowsToProcess) {
        row.status = "PROCESSING";
        row.batchId = batchId;
        row.userName = this.SysproUser.sysproOperator;
        //Update local record
        this.sysproService.updateBankImportLine(row, row.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));
      }

      const postObject = {
        "user": this.SysproUser, "detail": rowsToProcess
      };
      this.sysproService.postPaymentsToSyspro(postObject).subscribe(x => this.onArstpyPOSTAllSuccesful(x), error => { this.onArstpyPOSTAllFailed(error) });
    }
    else {
      this.alertService.showMessage('Warning', `You have not logged into Syspro`, MessageSeverity.warn);
      this.isPosting = false;
    }

  }


  onUpdateSuccesful(x: BankImport): void {

    this.resultRowsCache.push(x);
    this.resultRows = [...this.resultRowsCache];
    let index = this.stagedRows.findIndex(r => r.id === x.id)
    let temp = [...this.stagedRows];
    temp.splice(index, 1);
    temp.forEach((line, index, temp) => {
      (<any>line).index = index + 1;
    });

    this.stagedRows = [...temp];
  }

    onArstpyPOSTAllFailed(error: any) {
      this.loadingIndicator = false;
      this.alertService.stopLoadingMessage();
  }

  onArstpyPOSTAllSuccesful(x: BankImport): void {

    console.log("RESULT", x);
    //Update Local Records
    const rowsToProcess = this.resultRows.filter(r => r.batchId === x.batchId);
    for (var row of rowsToProcess) {
      row.status = "SUCCESS";
      row.journal = x.journal;
      row.trnYear = x.trnYear;
      row.trnMonth = x.trnMonth;
      row.message = "POSTED " + x.journal;

      //Update all records with success, journal details
      this.sysproService.updateBankImportLine(row, row.id).subscribe(x => this.onFinalUpdateSuccesful(x), error => this.onUpdateFailed(error));
    }

    this.loadingIndicator = false;
    this.alertService.stopLoadingMessage();
    this.isPosting = false;
    this.complete = true;

  }

  onFinalUpdateSuccesful(x: BankImport): void {
    console.log("NEW ROW", x);
    let index = this.resultRows.findIndex(r => r.id === x.id)
    let rowToUpdate = this.resultRows[index];
    rowToUpdate = x;
    this.resultRows = [...this.resultRows]; 
  }

  //processRow(row: BankImport, count: number) {
  //   this.posintgCount++;
  //   let detail = row;
  //  const postObject = {
  //    "user": this.SysproUser, "detail": detail
  //  };
  //  setTimeout(() => {
   
  //  this.sysproService.postPaymentsToSyspro(postObject).subscribe(x => this.onArstpyPOSTSuccesful(x, count), error => { this.onArstpyPOSTFailed(row, error, count) });
  //  }, 3000); //Time before execution
   
  //}


  //skipRow(row: BankImport, count: number) {
  //  this.successcount++;
  //  this.skipCount++;
  //  console.log("SKIPPED", count, this.successcount, row);
  //  let newRow = new BankImport();
  //  newRow = row;
  //  this.resultRowsCache.push(newRow);
  //  this.resultRows = [...this.resultRowsCache];


  //  let index = this.stagedRows.findIndex(x => x.id === x.id)
  //  let temp = [...this.stagedRows];
  //  temp.splice(index, 1);
  //  temp.forEach((line, index, temp) => {
  //    (<any>line).index = index + 1;
  //  });

  //  this.stagedRows = [...temp];
  //}


  //onNextRow(rowToMove: any): boolean {
  //  let newRow = new BankImport();
  //  newRow = rowToMove;
  //  this.resultRowsCache.push(newRow);
  //  this.resultRows = [...this.resultRowsCache];

  //  let temp = [...this.stagedRowsCache];
  //  temp.splice(this.index - 1, 1);
  //  temp.forEach((line, index, temp) => {
  //    (<any>line).index = index + 1;
  //  });

  //  this.stagedRows = [...temp];

  //  return true;
  //}

  onArstpyPOSTSuccesful(x: BankImport, count: number): void {
    this.successcount++;
    console.log("SUCCESS", count, this.successcount, this.processcount, x);
  
    if (this.successcount <= this.processcount) {
      if (x && x.id >= 1) {
        let newRow = new BankImport();
        newRow = x;
        this.resultRowsCache.push(newRow);
        this.resultRows = [...this.resultRowsCache];
        let index = this.stagedRows.findIndex(x => x.id === x.id)
        let temp = [...this.stagedRows];
        temp.splice(index, 1);
        temp.forEach((line, index, temp) => {
          (<any>line).index = index + 1;
        });

        this.stagedRows = [...temp];

        //Update local record
        this.sysproService.updateBankImportLine(newRow, newRow.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));

      }
      else {
        console.log(x);
      }
    }
    if (this.successcount == this.processcount) {
      this.wrapUp();
    }
  }
    onUpdateFailed(error: any): void {
      
    }

  validatePost(row?: BankImport) {
    return true;
  }

  onArstpyPOSTFailed(posteRow: BankImport, error: any, count: number): void {
    this.successcount++;
    this.failedCount++;
    console.log("FAILED", count, this.successcount, posteRow);
    let newRow = new BankImport();
    newRow = posteRow;
    newRow.status = "FAILED";
    newRow.message = error;
    this.resultRowsCache.push(newRow);
    this.resultRows = [...this.resultRowsCache];

    let index = this.stagedRows.findIndex(x => x.id === x.id)
    let temp = [...this.stagedRows];
    temp.splice(index, 1);
    temp.splice(count - 1, 1);
    temp.forEach((line, index, temp) => {
      (<any>line).index = index + 1;
    });

    this.stagedRows = [...temp];
    //Update local record
    this.sysproService.updateBankImportLine(newRow, newRow.id).subscribe(x => this.onUpdateSuccesful(x), error => this.onUpdateFailed(error));

    if (this.successcount == this.processcount) {
      this.wrapUp();
    }
    
  }


  private wrapUp() {
    this.loadingIndicator = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Finished processing : ' + this.successcount + ' records of which ' + this.skipCount + ' were skipped, and ' + this.failedCount + ' failed');

    this.isPosting = false;
    this.complete = true;
    this.stagedRows = null;

    this.gridHeigt = "height: 600px;";
  }

  onSessionFailed(error: any): void {
    throw new Error('Method not implemented.');
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
   // this.specificSearch();
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.show = false;
   // this.specificSearch();
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
   // this.specificSearch();
  }

  RefreshResults() {

    this.sysproService.getBankImportByFileName(this.currentFileName).subscribe(x => this.onHistoryLoadSuccesfull(x), error => this.onDataLoadFailed(error));
  }

  ExportResults() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Supplier", "Name", "Bank", "Branch", "Reference", "PayValue", "Message", "PayNumber"]
    };

    var filename = this.selectedPaymentNumber;
    var selectRows = [...this.sysprorejectedDetailList];
    //var selectedHeader = this.syspropaymentHeaderList;
    var exportRows = [];
    for (var row of selectRows) {
      //let date = new Date(selectedHeader.).toISOString().split('T')[0];
      exportRows.push({
        Supplier: row.supplier, Name: row.supplierName, Bank: row.supplierBank, Reference: row.supplierRefNo, PayValue: row.supplierPayValue, Message: row.message,
        BatchNumber: this.selectedPaymentNumber
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();

    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

}
