import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Permission } from 'src/app/models/permission.model';
import { AccountService } from 'src/app/services/account.service';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
  selector: 'repairs',
  templateUrl: './repairs.component.html',
  styleUrls: ['./repairs.component.scss'],
    animations: [fadeInOut]
})
export class RepairsComponent {

  isListActivated = false;
  isReprintActivated = false;
  isDashboardActivated = true;
  isCollapsed = false;

  fragmentSubscription: any;

  readonly reportsItsListTab = 'itslist';
  readonly reportsInvoiceReprintTab = 'reprint';
  readonly repairsDahboardTab = 'repairsdashboard';

  @ViewChild('tab')
  tab: BootstrapTabDirective;


  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService,
    private configurations: ConfigurationService) {
  }


  ngOnInit() {
   
    this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
    this.isCollapsed = !this.configurations.menuExpanded;
  }
  
  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }


  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  showContent(anchor: string) {

    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.reportsItsListTab}Tab`);
 
  }

  setNewTab(fragment) {
    const activeTab = fragment;

    this.isListActivated = activeTab == this.reportsItsListTab;
    this.isReprintActivated = activeTab == this.reportsInvoiceReprintTab;
    this.isDashboardActivated = activeTab == this.repairsDahboardTab;

    this.router.navigate([], { fragment: activeTab });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    this.isListActivated = activeTab == this.reportsItsListTab;
    this.isReprintActivated = activeTab == this.reportsInvoiceReprintTab;
    this.isDashboardActivated = activeTab == this.repairsDahboardTab;


    this.router.navigate([], { fragment: activeTab });
  }
   

  get canViewToolrepairs() {
    return this.accountService.userHasPermission(Permission.viewRepairsPermission);
  }

  get canManageToolrepairs() {
    return this.accountService.userHasPermission(Permission.manageRepairsPermission);
  }

}
