
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { SysproService } from '../../services/syspro.service';
import { ReportService } from '../../services/report.service';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { Permission } from '../../models/permission.model';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';
import { ArCustomer } from 'src/app/models/arcustomer.model';
import { ArCustomerEdit } from 'src/app/models/arcustomer-edit.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { CartDetail } from 'src/app/models/cart-detail.model';
import { CartHeader } from 'src/app/models/cart-header.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Calculations } from 'src/app/services/calculations.service';
import { QuoteList } from 'src/app/models/quote-list.model';
import { ngxCsv } from 'ngx-csv';


@Component({
  selector: 'syspro-quote-management-web',
  templateUrl: './syspro-quote-management-web.component.html',
  styleUrls: ['./syspro-quote-management-web.component.scss']
})
export class SysproQuoteManagementWebComponent implements OnInit, AfterViewInit {

  private cart: CartHeader = new CartHeader();

  columns: any[] = [];
  rows: QuoteList[] = [];
    rowsCache: QuoteList[] = [];
    cartRows: CartDetail[] = [];
    editedCustomer: ArCustomerEdit;
    sourceCustomer: ArCustomerEdit;
    editingCustomerName: { customer: string };
  loadingIndicator: boolean;

  @Input()
  isViewOnly: boolean;

  @ViewChild('quotesModal')
  quotesModal: ModalDirective;

    @ViewChild('indexTemplate')
    indexTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;

  @ViewChild('stockCodeTemplate')
  stockCodeTemplate: TemplateRef<any>;

  @ViewChild('descriptionTemplate')
  descriptionTemplate: TemplateRef<any>;

    @ViewChild('orderQtyTemplate')
    orderQtyTemplate: TemplateRef<any>;

    @ViewChild('discountTemplate')
    discountTemplate: TemplateRef<any>;

    @ViewChild('priceTemplate')
    priceTemplate: TemplateRef<any>;

    @ViewChild('totalTemplate')
    totalTemplate: TemplateRef<any>;
  
    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('previewTemplate')
  previewTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

    @ViewChild('customerEditor')
    customerEditor: SysproCustomerViewComponent;
    inventoryList: Inventory[];

  @ViewChild('salesOrderTemplate')
  salesOrderTemplate: TemplateRef<any>;

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;
    cartId: string;
    id: string;
    gridHeight: number = 0;
  TotalItems: number = 0;
  TotalLines: number = 0;
    TotalExcl: number = 0;
    TotalDiscount: number = 0;
    TotalExclAfterDiscount: number = 0;
    TotalVat: number = 0;
    TotalIncl: number = 0;
    isSaving: boolean;
    selectedItems: any;
    isNavigating: boolean;
    cartSaved: boolean;
    quoteNumber: any;
  quotes: QuoteList[];

  check30Days: boolean;
  searchType: string = "";
  searchText: string = "";
  searchResults: QuoteList[] = [];
  searchResultSelected: string = "";
  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @ViewChild('searchInput')
  searchInput: ElementRef;
  searchValue: string = "";
  smartsearch: string = "";
    selectedCustomer: string;
    selectedQuote: string;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService, private router: Router, private route: ActivatedRoute) {
    }


  ngOnInit() {
    //this.searchValue = "";
    this.check30Days = false;
    let action = sessionStorage.getItem(localStorageActions.Customer);
    let quoteNumber = sessionStorage.getItem(localStorageActions.QuoteNumber);
    //let searchValue = sessionStorage.getItem(localStorageActions.SearchValue);

    this.quoteNumber = quoteNumber;
    this.id = action;

      this.columns = [
        { prop: 'quoteNumber', name: 'Quote Number', width: 150, cellTemplate: this.selectTemplate},
        { prop: 'quoteHistory', name: 'Description', width: 650},
        { prop: 'convertedRef', name: 'Converted SO', width: 100, cellTemplate: this.salesOrderTemplate  }
      ];

    this.columns.push({ name: '', width: 200, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

    this.searchType = "customer";
    this.searchText = "FBWEB";
    this.specificSearch();

  }

  get loadCache(): boolean {
    return this.configurations.loadCache;
  }


  onSearchChanged(e: any) {
    let test = e;
    let value = this.smartsearch;
    if (value.trim() != "") {
      this.rows = this.rowsCache.filter(r => Utilities.searchArray(value,
        false, r.customer, r.quoteNumber, r.quoteHistory, r.convertedRef, r.quoteStatus, r.createDate, r.searchItems, r.customerPO));
      this.searchValue = value;

    }
    else {
      this.searchValue = "";
      this.rows = [...this.rowsCache];
    }
  }

  viewPDF(row: QuoteList) {
    console.log(row);
    
    this.reportService.getQuotePDF(row.quoteNumber, this.accountService.currentUser.aspnetApplication).subscribe(x => this.getPDF(x, row.quoteNumber), error => this.onDataLoadFailed(error));
  }
  getPDF(x: Blob, quoteNumber: string): void {
    this.reportService.getFileStream(quoteNumber).subscribe(x => this.onFileSuccessful(x), error => this.onDataLoadFailed(error));
    }

  viewQuote(row: QuoteList) {
    this.setLocalCustomer(row.customer, row.quoteNumber);
    this.router.navigate(['../../quotes'], { fragment: 'quote', queryParams: { customer: row.customer } });
  }


  previewQuote(row: QuoteList) {
    this.setLocalCustomer(row.customer, row.quoteNumber);

    if (!!this.selectedQuote) {
      if (!this.quotesModal) {
        setTimeout(() => {
          if (this.quotesModal) {
            this.quotesModal.show();
          }
        });
      }
      else {
        this.quotesModal.show();
      }
    }
  }

  quotesModalHide() {
    this.selectedQuote = null;
    this.quotesModal.hide();
  }

  gotToQuote() {
    this.router.navigate(['../../quotes'], { fragment: 'quote', queryParams: { customer: this.selectedCustomer } });
  }

  viewSalesOrder(row: QuoteList) {
    if (sessionStorage.getItem(localStorageActions.Customer) != row.customer) {
      sessionStorage.setItem(localStorageActions.Customer, row.customer);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != row.convertedRef) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, row.convertedRef);
    }
    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: row.customer } });
  }

  setLocalCustomer(customer: string, quoteNumber: string) {

    this.selectedCustomer = customer;
    this.selectedQuote = quoteNumber;
    if (sessionStorage.getItem(localStorageActions.QuoteNumber) != quoteNumber) {
      sessionStorage.setItem(localStorageActions.QuoteNumber, quoteNumber);
    }
    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }
    if (this.searchValue && this.searchValue.length > 1) {
      //if (sessionStorage.getItem(localStorageActions.SearchValue) != this.searchValue) {
      //  sessionStorage.setItem(localStorageActions.SearchValue, this.searchValue);
      //}
    }
    else {
      sessionStorage.removeItem(localStorageActions.SearchValue);
    }
  }


    ngAfterViewInit() {
      this.columns = [
        { prop: 'quoteNumber', name: 'Quote Number', width: 150, cellTemplate: this.selectTemplate },
        { prop: 'quoteHistory', name: 'Description', width: 650 },
        { prop: 'convertedRef', name: 'Converted SO', width: 100, cellTemplate: this.salesOrderTemplate }
      ];

      this.columns.push({ name: '', width: 200, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    }
  

  loadData() {

    this.searchType = "customer";
    if (this.loadCache == true) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getQuoteSearchAll('TODO').subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
    }

  }

  load30Days() {
    this.check30Days = !this.check30Days;
    if (this.check30Days) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sysproService.getQuoteNumberSearchByCriteria("ALL", "30Days").subscribe(
        x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
    }
  }

  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
     
        this.sysproService.getQuoteNumberSearchByCriteria(this.searchType, this.searchText).subscribe(
          x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
      
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  onSpecificSearchSuccesful(x: QuoteList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x;

  }
    onSearchSuccesful(x: QuoteList[]): void {
      console.log(x);
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.quotes = x;
      x.forEach((quoteNumber, index, x) => {
        (<any>quoteNumber).index = index + 1;
      });

      this.rowsCache = [...x];
      this.rows = x; //.slice(0, 18);
  }

  onFileSuccessful(x: Blob): void {
    var test = x;
    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = "Quote.pdf";
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    console.log(test);
    }


  get branchCode(): string {
    return this.configurations.branchCode;
  }
    
  onCartHeaderLoadSuccessful(cart: CartHeader) {
    this.cart = cart;
    console.log(this.cart);
    
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }
  
  viewCustomer() {
    this.router.navigate(['../../orders'], { fragment: 'view', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goAddItems() {
    this.router.navigate(['../../orders'], { fragment: 'inventory', queryParams: { customer: this.id, cartId: this.cartId } });
  }
  
  goAddNSItems() {
    this.router.navigate(['../../orders'], { fragment: 'nonstock', queryParams: { customer: this.id, cartId: this.cartId } });
  }

  goCreateQuote() {
    this.isNavigating = true;
    //this.save("cart");
    //if (this.cartlinenumber > 0 && this.rowsCache.filter(x => x.orderqty > 0).length <= 0) {
    //  this.router.navigate(['../../orders'], { fragment: 'cart', queryParams: { customer: this.customer, cartId: this.cartId } });
    //}
    this.sysproService.postCartToQuote(this.cartId).subscribe(_response => this.saveSuccessHelper(_response), error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(quoteNumber?: any) {

    this.cartSaved = true;

    if (quoteNumber) {
      console.log(quoteNumber[0].quoteNumber);
      this.quoteNumber = quoteNumber[0].quoteNumber;

      this.reportService.getQuotePDF(this.quoteNumber, this.accountService.currentUser.aspnetApplication).subscribe(x => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], { type: "application/pdf" });

        console.log(x);

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = "Je kar.pdf";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
      // Object.assign(this.cartRows, cart);      
    }

    if (this.isNavigating) {
      this.router.navigate(['../orders'], { fragment: 'cart', queryParams: { customer: this.id, cartId: this.cartId } });
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', this.quoteNumber + ` Created successfully`, MessageSeverity.success);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    //if (this.changesFailedCallback) {
    //  this.changesFailedCallback();
    //}
  }

  private save(location?) {
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    
    for (var product of this.rowsCache) {
    }


  }

  viewInventory() {

  }

  calcOrderQty(row: any, event) {
    var val = event.target.value;
    row.orderQty = val;

    let total = (row.orderQty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountPercentage1) / 100);
    row.lineTotal = total;
  }


  calcDisc(row: any, event) {
    var val = event.target.value;
    row.discountPercentage1 = val;

    let total = (row.orderQty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountPercentage1) / 100);
    row.lineTotal = total;
  }


  calcPrice(row: any, event) {
    var val = event.target.value;
    row.sellingPrice = val;

    let total = (row.orderQty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountPercentage1) / 100);
    row.lineTotal = total;
  }


  calcTotal(row: any) {
    let total = (row.orderQty * (row.sellingPrice / row.priceConvFac)) * ((100 - row.discountPercentage1) / 100);
    row.lineTotal = total;
  }

  setDelete(row: any) {
    if (row.selected) {
      this.selectedItems.push(row.lineNumber);
    }
  }

  viewCustomerNotes(row: ArCustomerEdit) {
    sessionStorage.setItem(localStorageActions.Customer, row.customer);
    this.router.navigate(['../../customers'], { fragment: 'notes', queryParams: { customer: row.customer } });
  }

   get canViewCustomers() {
       return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

  get canManageCustomers() {
    return this.accountService.userHasPermission(Permission.manageCustomersPermission);
  }


  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["QuoteNumber", "Customer", "PO", "Status", "Date", "Total",  "SalesOrder"]
    };

    var filename = "Quotes";
    var selectRows = [...this.rowsCache];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.createDate).toISOString().split('T')[0];
      exportRows.push({
        QuoteNumber: row.quoteNumber, Customer: row.customer, PO: row.customerPO, Status: row.quoteStatus, Date: date, Total: row.totalIncl, SalesOrder: row.convertedRef
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }
}
