import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ArCustomer } from '../../../models/arcustomer.model';
import { CoreAudit } from '../../../models/core-audit.model';
import { Email } from '../../../models/email.model';
import { localStorageActions } from '../../../models/enums';
import { CoreJCStatus, JobCard, JobCardStatusHistory, JobCardSuppliers } from '../../../models/job-card.model';
import { PurchaseOrderList } from '../../../models/purchaseorder-list.model';
import { QuoteList } from '../../../models/quote-list.model';
import { SalesOrderList } from '../../../models/salesorder-list.model';
import { Supplier } from '../../../models/supplier.model';
import { SysproUser } from '../../../models/syspro-user.model';
import { User } from '../../../models/user.model';
import { AccountService } from '../../../services/account.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';

@Component({
  selector: 'customer-tool-repairs',
  templateUrl: './customer-tool-repairs.component.html',
  styleUrls: ['./customer-tool-repairs.component.scss']
})
export class SysproCustomerToolrepairsComponent implements OnInit, AfterViewInit {


  @ViewChild('currentCustomer')
  currentCustomer: string;

  @ViewChild('customers')
  private customers;

  @ViewChild('customerSelector')
  private customerSelector;

  customerSearch: ArCustomer[];
  customerHeading: ArCustomer = new ArCustomer();

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Today': [moment().subtract(0, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };

  selected: { startDate: Moment, endDate: Moment };

  show: boolean;
  loadingIndicator: boolean;
  isHistoryPanelOpen: boolean = false;


  @ViewChild('searchInput')
  searchInput: ElementRef;
  searchValue: string = "";

  @Input() isViewOnly: boolean;

  auditObj = new CoreAudit();
  emailObject: Email = new Email();

  columns: any[] = [];
  rows: JobCard[] = [];
  rowsCache: JobCard[] = [];

  jobCard: JobCard;
  jobCardDetail: JobCard;

  @ViewChild('jobCardDetailModal') jobCardDetailModal: ModalDirective;

  statuses: CoreJCStatus[] = [];

  customersCache: ArCustomer[] = [];
  selectedCustomerCode: string = '';
  selectedCustomerName: string = '';

  @ViewChild('dateTemplate') dateTemplate: TemplateRef<any>;
  @ViewChild('selectTemplate') selectTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate: TemplateRef<any>;
  @ViewChild('customerTemplate') customerTemplate: TemplateRef<any>;
  @ViewChild('quoteTemplate') quoteTemplate: TemplateRef<any>;
  @ViewChild('invoiceTemplate') invoiceTemplate: TemplateRef<any>;
  @ViewChild('purchaseOrderTemplate') purchaseOrderTemplate: TemplateRef<any>;

  @ViewChild('statusListSelector') private statusListSelector;
  selectedStatus: string = "";
  currentStatus: string;
  smartsearch: string = "";

  invoiceNumber: string;

  // Properties for search functionality
  searchCustomerType: string = 'customer'; // Default to customer code
  searchCustomerText: string = '';
  searchCustomerResults: ArCustomer[] = [];
  searchCustomerResultSelected: ArCustomer;

  @ViewChild('searchByCustomerSelector') private searchByCustomerSelector;
  @ViewChild('customerSearchResultsSelector') private customerSearchResultsSelector;


  @ViewChild('quoteSearchModal') quoteSearchModal: ModalDirective;
  @Input() selectedQuote: string;
  showQuotePopup: boolean;

  searchQuoteType: string = 'customer'; // Default to customer code
  searchQuoteText: string = '';
  searchQuoteResults: QuoteList[] = [];
  searchQuoteResultSelected: QuoteList;
  quoteSearch: boolean;

  @ViewChild('searchByQuoteSelector')
  private searchByQuoteSelector;

  @ViewChild('QuoteSearchResultsSelector')
  private QuoteSearchResultsSelector;

  searchType: string;
  searchText: string;
  private selectUndefinedOptionValue: string = "Please Select";

  dateRows: JobCard[];

  @ViewChild('invoiceSearchModal')
  invoiceSearchModal: ModalDirective;

  @Input()
  selectedInvoice: string;
  showInvoicePopup: boolean;

  searchInvoiceType: string = 'customer'; // Default to customer code
  searchInvoiceText: string = '';
  searchInvoiceResults: SalesOrderList[] = [];
  searchInvoiceResultSelected: SalesOrderList;
  invoiceSearch: boolean;

  @ViewChild('searchByInvoiceSelector')
  private searchByInvoiceSelector;

  @ViewChild('InvoiceSearchResultsSelector')
  private InvoiceSearchResultsSelector;
  selectedSalesOrder: string;
  editJobCard: boolean;

  @ViewChild('supplierSelector') supplierSelector;
  @ViewChild('currentSupplier') currentSupplier: Supplier;
  @ViewChild('suppliers') private suppliers;
  suppliersCache: Supplier[] = [];
  selectedSupplier: Supplier;
  addselectedSupplier: Supplier;

  @ViewChild('salesPerson') private salesPerson;
  usersCache: SysproUser[] = [];
  selectedSalesPerson: User;

  jobCardStatusHistory: JobCardStatusHistory[];
  oldStatus: number; oldStatusId: number = null;

  @ViewChild('poTemplate')
  poTemplate: TemplateRef<any>;

  @ViewChild('purchaseOrderList')
  purchaseOrderList: TemplateRef<any>;

  @ViewChild('purchaseOrderListSelector')
  private purchaseOrderListSelector;

  private selectUndefinedPOOptionValue: string = "Please Select";
  showPurchaseOrderPopup: boolean;
  purchaseOrderSearch: boolean;

  @ViewChild('purchaseOrderSearchModal')
  purchaseOrderSearchModal: ModalDirective;

  @Input()
  selectedPurchaseOrder: string;

  selectedFilterStatus: number = 0;
  @ViewChild('statusFilter')
  private statusFilter;

  sysproUsers: SysproUser[];

  @ViewChild('operators') private operators;
  @ViewChild('operatorSelector') private operatorSelector;
  allOperators: SysproUser[] = [];
  selectedOperator: string;
  jobCardUsers: any;
  src: string;

  predefinedComments: any[] = [];
  selectedComment: string = '';
  customComment: any;
  selectedCompany: string;

  @ViewChild('emailModal')
  emailModal: ModalDirective;
  showCustomerEmailModal: boolean;
  selectedSupplierIndex: number | null = null;
    searchResultSelected: any;
    searchResults: ArCustomer[];
  allRows: JobCard[];


  @ViewChild('searchResultSelector')
  private searchResultSelector;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService) { }

  // #region Lifecycle Hooks
  ngOnInit() {
    this.selectedCompany = this.application === "MaxArcusOnline" ? "M" : "F";
    this.searchType = "customer";
    this.checkCustomerInMemory();

    this.selected = {
      startDate: moment().startOf('year'),
      endDate: moment().endOf('month')
    };

    this.setData();
    this.loadData();
  }


  ngAfterViewInit() {
    this.setData();
  }

  checkCustomerInMemory() {

    const savedCustomer = sessionStorage.getItem(localStorageActions.Customer);
    if (savedCustomer) {
      this.currentCustomer = savedCustomer;
      this.loadCustomerData();
    }
  }

  loadCustomerData() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.sysproService.getCustomerDetail(this.currentCustomer)
      .subscribe(
        customer => {
          this.customerHeading = customer;
          this.loadJobCardsForCustomer();
        },
        error => this.onSearchFailed(error)
      );
  }

  loadJobCardsForCustomer() {
    this.fowkesService.getJobCards().subscribe(
      jobCards => this.onSearchSuccessful(jobCards),
      error => {
        this.alertService.showStickyMessage('', 'No Job Cards found for your search', MessageSeverity.warn);
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      }
    );
  }

  onCustomerLoadSuccessful(x: ArCustomer[]): void {
    this.customerSearch = x;
    this.customerHeading = this.customerSearch.find(x => x.customer == this.currentCustomer);
    setTimeout(() => {
      if (this.customerSelector) {
        this.customerSelector.refresh();
      }
    });
  }

  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchResults = null;
      this.searchResultSelected = null;

      this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  onSpecificSearchSuccesful(x: ArCustomer[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.rows = null;
    this.rowsCache = null;

    if (x && x.length >= 1) {

      this.searchResults = x;

      if (x.length === 1) {
        this.searchResultSelected = x[0];
        this.loadCustomer();
      }
    }
    else {
      this.alertService.showStickyMessage('No results', 'Customer could not be found',
        MessageSeverity.warn);
    }


  }

  private loadCustomer() {
      this.currentCustomer = this.searchResultSelected.customer;
    sessionStorage.removeItem(localStorageActions.Customer);
    sessionStorage.setItem(localStorageActions.Customer, this.currentCustomer);

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.customerHeading = this.searchResultSelected;
    this.loadJobCardsForCustomer();
   
  }
  // #endregion

  // #region Data Setup and Loading
  private setData() {
    this.columns = [
      { prop: 'jobCardNumber', name: 'Job Card #', width: 90, cellTemplate: this.selectTemplate, cellClass: "center" },
      { prop: 'dateMachineReceived', name: 'Received Date', width: 90, cellTemplate: this.dateTemplate, cellClass: "center" },
      { prop: 'statusId', name: 'Status', width: 210, cellTemplate: this.statusTemplate },
      { prop: 'customerCode', name: 'Customer', width: 200, cellTemplate: this.customerTemplate },
      { prop: 'machineDescription', name: 'Machine Description', width: 200 },
      { prop: 'quoteNumber', name: 'Quote Number', width: 120, cellTemplate: this.quoteTemplate },
      { prop: 'salesOrderNumber', name: 'Sales Order Number', width: 120, cellTemplate: this.invoiceTemplate },
      { prop: 'purchaseOrderNumber', name: 'Purchase Order Number', width: 150, cellTemplate: this.poTemplate },
      { prop: 'assignedTechnician', name: 'Technician', width: 150 },
      { prop: 'completionDate', name: 'Completion Date', width: 120, cellTemplate: this.dateTemplate },
      { prop: 'costOfRepair', name: 'Cost of Repair', width: 120, cellClass: "right" }
    ];
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.getStatuses();

    this.fowkesService.getJobCards().subscribe(
      jobCards => this.onSearchSuccessful(jobCards),
      error => {
        this.alertService.showStickyMessage('', 'No Job Cards found for your search', MessageSeverity.warn);
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      }
    );
  }
  // #endregion

  // #region Event Handlers and Data Processing
  onSearchSuccessful(jobCards: JobCard[]): void {
    this.rows = null;
    this.rowsCache = null;


    jobCards.forEach(jobCard => {
      const status = this.statuses.find(s => s.statusId === jobCard.statusId);
      jobCard.statusName = status ? status.statusName : 'Unknown Status';

      if (jobCard.salesOrderNumber) {
        this.sysproService.getPurchaseOrderSearchByCriteria("SALESORDER", jobCard.salesOrderNumber)
          .subscribe(poResults => {
            jobCard.poNumbers = poResults;
            jobCard.purchaseOrderNumber = poResults.length > 0 ? "Please Select" : '';
          }, error => console.error('Error loading purchase orders for sales order', error));
      }
    });

    this.rows = jobCards.filter(j => j.company == this.selectedCompany && j.customerCode == this.currentCustomer);
    this.rowsCache = this.rows;


    this.allRows = jobCards;
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  getStatuses() {
    this.fowkesService.getJobCardStatuses()
      .subscribe((statuses: CoreJCStatus[]) => {
        this.statuses = statuses;
        this.selectedFilterStatus = 0;
      }, error => console.error("Error fetching statuses: ", error));
  }
// #endregion


  specificCustomerSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.searchResults = null;
      this.searchResultSelected = null;

      this.sysproService.getCustomerSearchByCriteria(this.searchType, this.searchText).subscribe(
        x => this.onSpecificSearchSuccesful(x), error => this.onSearchFailed(error));
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }

  viewCustomerSelected() {
    this.rows = null;
    this.rowsCache = null;

    if (this.searchResultSelected) {
      this.loadCustomer();
    }

  }
  

  viewJobCard(row: JobCard) {

    this.jobCardDetail = new JobCard();
    // Fetch the job card details based on jobCardNumber or id from the row
    this.fowkesService.getJobCardById(row.id).subscribe(
      (jobCard: JobCard) => {

        this.jobCardDetail = jobCard; // Assign the fetched job card to jobCardDetail
      
        if (this.jobCardDetail.statusId) {
          const status = this.statuses.find(s => s.statusId === this.jobCardDetail.statusId);
          this.jobCardDetail.statusName = status ? status.statusName : 'Unknown Status';
        }

        this.jobCardDetail.customerContactInfo = jobCard.customerContactInfo;
        this.jobCardDetail.jobContactName = jobCard.jobContactName;
        this.jobCardDetail.jobContactEmail = jobCard.jobContactEmail;
        this.jobCardDetail.jobContactTelephone = jobCard.jobContactTelephone;
        this.jobCardDetail.assignedTechnician = jobCard.assignedTechnician;

        this.selectedCompany = jobCard.company || (this.application === 'MaxArcusOnline' ? 'M' : 'F');

        this.selectedOperator = jobCard.salesPersonOperator;

        if (this.jobCardDetail.purchaseOrderNumber) {
          this.sysproService.getPurchaseOrderSearchByCriteria("SALESORDER", this.jobCardDetail.salesOrderNumber)
            .subscribe(x => {
              this.jobCardDetail.poNumbers = x;  // Attach the PO numbers to the job card
              if (x && x.length > 0) {
                this.jobCardDetail.purchaseOrderNumber = "Please Select";
              } else {
                this.jobCardDetail.purchaseOrderNumber = ''; // Reset if no POs are found
              }
            }, error => {
              console.error('Error loading purchase orders for sales order', error);
            });
        }

        this.fowkesService.getJobCardStatusHistory(row.id).subscribe(
          (x: JobCardStatusHistory[]) => {
            // Filter to include entries with "status" updates or "Job Created" updateType and sort by ChangeDate in descending order
            this.jobCardStatusHistory = x
              .filter(history =>
                history.updateType &&
                (history.updateType.toLowerCase().includes('status') || history.updateType.toLowerCase() === 'job created')
              )
              .sort((a, b) => {
                return new Date(b.changeDate).getTime() - new Date(a.changeDate).getTime(); // Sort by ChangeDate in descending order
              });
          },
          (error) => {
            console.error('Failed to load job card status history', error);
          }
        );

        // Open the modal for editing the job card
        if (!this.jobCardDetailModal) {
          setTimeout(() => {
            if (this.jobCardDetailModal) {
              this.jobCardDetailModal.show();
            }
          });
        } else {
          this.jobCardDetailModal.show();
        }
      },
      (error) => {
        this.alertService.showStickyMessage('Load Error', 'Unable to load job card details', MessageSeverity.error, error);
      }
    );
  }

  viewQuoteSelected() {
    if (this.searchQuoteResultSelected) {
      this.selectQuote(this.searchQuoteResultSelected);
    }
  }

  selectQuote(quote: QuoteList) {
    this.selectedQuote = quote.quoteNumber;
    if (sessionStorage.getItem(localStorageActions.Customer) != this.jobCardDetail.customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, this.jobCardDetail.customerCode);
    }
    if (sessionStorage.getItem(localStorageActions.QuoteNumber) != this.selectedQuote) {
      sessionStorage.setItem(localStorageActions.QuoteNumber, this.selectedQuote);
    }
  }

  onSpecificInvoiceSearchSuccesful(invoices: SalesOrderList[]): void {
    this.searchInvoiceResults = invoices;

    // If only one invoice is returned, automatically select it
    if (invoices.length === 1) {
      this.searchInvoiceResultSelected = invoices[0];
      this.selectInvoice(this.searchInvoiceResultSelected);
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  selectInvoice(invoice: SalesOrderList) {
    this.selectedInvoice = invoice.invoiceNumber;
    this.selectedSalesOrder = invoice.salesOrderNumber;

    this.jobCardDetail.invoiceNumber = invoice.salesOrderNumber;
    this.jobCardDetail.salesOrderNumber = invoice.salesOrderNumber;

    if (invoice.poNumber != "" && invoice.poNumber != "NA") {
      invoice.poNumber = "Please Select";
      this.SetPONumbers(invoice);
    }
  }

  SetPONumbers(salesOrder: SalesOrderList) {
    this.sysproService.getPurchaseOrderSearchByCriteriaByCompany("SALESORDER", salesOrder.salesOrderNumber, this.selectedCompany).
      subscribe(x => this.POSearchSuccesful(salesOrder, x), error => this.onDataLoadFailed(error));
  }

  POSearchSuccesful(salesOrder: SalesOrderList, x: PurchaseOrderList[]): void {
    let poList = x;
    x.forEach((poNumber, index, x) => {
      (<any>poNumber).index = index + 1;
    });

    if (poList && poList.length > 0) {
      // Concatenate all the purchase order numbers into a single string
      salesOrder.poNumbers = x;
      this.jobCardDetail.poNumbers = x;
      //this.jobCardDetail.purchaseOrderNumber = x[0].purchaseOrder;
      this.jobCardDetail.purchaseOrderNumber = x.map(po => po.purchaseOrder).join(', ');
    } else {
      // If no POs are found, reset the purchase order number
      this.jobCardDetail.purchaseOrderNumber = '';
    }
  }

  quoteSearchModalHide() {
    // Remove grey-out effect from the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.remove('greyed-out');
    }
    this.showQuotePopup = false;
    this.quoteSearch = false;
    this.searchQuoteText = "";
    this.searchQuoteResultSelected = null;
    this.selectedQuote = "";
    if (this.quoteSearchModal) {
      this.quoteSearchModal.hide();
    }
  }

  previewQuote(jobCardDetail: JobCard) {
    this.showQuotePopup = true;
    this.quoteSearch = false;
    this.selectedQuote = jobCardDetail.quoteNumber;
    this.jobCardDetail = jobCardDetail;

    if (sessionStorage.getItem(localStorageActions.Customer) != jobCardDetail.customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, jobCardDetail.customerCode);
    }
    if (sessionStorage.getItem(localStorageActions.QuoteNumber) != jobCardDetail.quoteNumber) {
      sessionStorage.setItem(localStorageActions.QuoteNumber, jobCardDetail.quoteNumber);
    }

    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    if (!this.quoteSearchModal) {
      setTimeout(() => {
        if (this.quoteSearchModal) {
          this.quoteSearchModal.show();
        }
      });
    }
    else {
      this.quoteSearchModal.show();
    }
  }

  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }

  //handleJobCardSaveError(error: any) {
  //  this.alertService.stopLoadingMessage();
  //  this.loadingIndicator = false;
  //  this.alertService.showStickyMessage('Save Error', 'An error occurred while saving the Job Card.', MessageSeverity.error, error);
  //}

 
  viewInvoiceSelected() {
    if (this.searchInvoiceResultSelected) {
      this.selectInvoice(this.searchInvoiceResultSelected);
    }
  }

  previewSupplierInvoice(jobCardDetail: JobCard) {

    this.jobCardDetail = jobCardDetail;
    this.showInvoicePopup = true;
    this.invoiceSearch = false;
    this.selectedInvoice = this.jobCardDetail.originalInvoice;
    this.selectedSalesOrder = this.jobCardDetail.originalInvoice;


    if (sessionStorage.getItem(localStorageActions.Customer) != jobCardDetail.customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, jobCardDetail.customerCode);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != this.selectedSalesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.selectedSalesOrder);
    }
    if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != this.selectedInvoice) {
      sessionStorage.setItem(localStorageActions.InvoiceNumber, this.selectedInvoice);
    }


    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    if (!this.invoiceSearchModal) {
      setTimeout(() => {
        if (this.invoiceSearchModal) {
          this.invoiceSearchModal.show();
        }
      });
    }
    else {
      this.invoiceSearchModal.show();
    }
  }

  previewInvoice(jobCardDetail: JobCard) {

    this.jobCardDetail = jobCardDetail;

    this.showInvoicePopup = true;
    this.invoiceSearch = false;

    this.selectedInvoice = jobCardDetail.invoiceNumber;
    this.selectedSalesOrder = jobCardDetail.salesOrderNumber;


    if (sessionStorage.getItem(localStorageActions.Customer) != jobCardDetail.customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, jobCardDetail.customerCode);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != this.selectedSalesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.selectedSalesOrder);
    }
    if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != this.selectedInvoice) {
      sessionStorage.setItem(localStorageActions.InvoiceNumber, this.selectedInvoice);
    }

    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    if (!this.invoiceSearchModal) {
      setTimeout(() => {
        if (this.invoiceSearchModal) {
          this.invoiceSearchModal.show();
        }
      });
    }
    else {
      this.invoiceSearchModal.show();
    }
  }

  invoiceSearchModalHide() {
    // Remove grey-out effect from the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.remove('greyed-out');
    }
    this.showInvoicePopup = false;
    this.invoiceSearch = false;
    this.searchInvoiceText = "";
    this.searchInvoiceResultSelected = null;
    this.selectedInvoice = "";
    this.selectedSalesOrder = "";
    this.selectedSupplierIndex = null;

    if (this.invoiceSearchModal) {
      this.invoiceSearchModal.hide();
    }
  }

  previewPurchaseOrderDetail(jobCardDetail: JobCard, po: PurchaseOrderList) {
    this.showPurchaseOrderPopup = true;
    this.purchaseOrderSearch = false;

    this.jobCardDetail = jobCardDetail;

    this.selectedInvoice = jobCardDetail.invoiceNumber;
    this.selectedSalesOrder = jobCardDetail.salesOrderNumber;

    if (po && po.purchaseOrder) {

      this.selectedPurchaseOrder = po.purchaseOrder;
    }
    else {
      this.selectedPurchaseOrder = this.jobCardDetail.purchaseOrderNumber;
    }

    if (sessionStorage.getItem(localStorageActions.Customer) != jobCardDetail.customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, jobCardDetail.customerCode);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != this.selectedSalesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.selectedSalesOrder);
    }
    if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != this.selectedInvoice) {
      sessionStorage.setItem(localStorageActions.InvoiceNumber, this.selectedInvoice);
    }
    if (sessionStorage.getItem(localStorageActions.PurchaseOrder) != this.selectedPurchaseOrder) {
      sessionStorage.setItem(localStorageActions.PurchaseOrder, this.selectedPurchaseOrder);
    }

    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    if (!this.purchaseOrderSearchModal) {
      setTimeout(() => {
        if (this.purchaseOrderSearchModal) {
          this.purchaseOrderSearchModal.show();
        }
      });
    }
    else {
      this.purchaseOrderSearchModal.show();
    }
  }

  purchaseOrderSearchModalHide() {
    // Remove grey-out effect from the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.remove('greyed-out');
    }
    this.showPurchaseOrderPopup = false;
    this.purchaseOrderSearch = false;
    this.selectedInvoice = "";
    this.selectedSalesOrder = "";
    this.selectedPurchaseOrder = "";

    if (this.purchaseOrderSearchModal) {
      this.purchaseOrderSearchModal.hide();
    }
  }

  jobCardDetailModalHide() {
    this.jobCardDetail = null;
    this.jobCardStatusHistory = null;
    this.isHistoryPanelOpen = false;

    if (this.jobCardDetailModal) {
      this.jobCardDetailModal.hide();
    }
  }



  // #region Working functions
  get branchCode(): string {
    return this.configurations.branchCode;
  }

  get application(): string {
    return this.configurations.applicationName;
  }


  //toggleHistoryPanel() {
  //  this.isHistoryPanelOpen = !this.isHistoryPanelOpen;
  //}

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  getTodayDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  filterChanged() {
    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    // Filter rowsCache by the date range
    this.dateRows = this.rowsCache.filter(d => {
      const createdDate = new Date(d.createdDate);
      return createdDate >= startD && createdDate <= endD;
    });

    this.applySmartSearch();
  }

  // Smart Search filter
  applySmartSearch() {
    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    // Filter rowsCache by the date range
    this.dateRows = this.rowsCache.filter(d => {
      const createdDate = new Date(d.createdDate);
      return createdDate >= startD && createdDate <= endD;
    });

    let value = this.smartsearch.trim().toLowerCase();

    // Step 3: Filter dateRows based on the smart search
    let filteredRows = this.dateRows.filter(r => {
      return Utilities.searchArray(value,
        false, r.machineDescription, r.binNumber, r.quoteNumber, r.customerCode, r.customerName, r.customerContactInfo, r.invoiceNumber, r.jobCardNumber, r.assignedTechnician, r.salesPersonName, r.salespersonEmail);
    });

    // Step 4: Filter based on selected status if one is chosen
    if (this.selectedFilterStatus && this.selectedFilterStatus != 0) {
      filteredRows = filteredRows.filter(r => r.statusId === Number(this.selectedFilterStatus));
    }

    // Update the rows to display based on all filters
    this.rows = filteredRows;
  }

  //reset() {
  //  this.jobCardDetailModalHide();
  //  this.loadData();
  //}

  onSearchChanged(e: any) {
    this.applySmartSearch();
  }
  // #endregion Working functions

}
