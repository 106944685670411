
import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ArCustomer } from '../../../models/arcustomer.model';
import { BarcodeReference } from '../../../models/barcode-reference.model';
import { CoreAudit } from '../../../models/core-audit.model';
import { Email } from '../../../models/email.model';
import { localStorageActions } from '../../../models/enums';
import { CoreJCStatus, JobCard, JobCardStatusHistory, JobCardSuppliers } from '../../../models/job-card.model';
import { PurchaseOrderList } from '../../../models/purchaseorder-list.model';
import { QuoteList } from '../../../models/quote-list.model';
import { SalesOrderList } from '../../../models/salesorder-list.model';
import { Supplier } from '../../../models/supplier.model';
import { SysproUser } from '../../../models/syspro-user.model';
import { User } from '../../../models/user.model';
import { OptIns, Outbox } from '../../../models/wapp-inbox.model';
import { AccountService } from '../../../services/account.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { ReportService } from '../../../services/report.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';


@Component({
  selector: 'tool-repairs-dashboard',
  templateUrl: './tool-repairs-dashboard.component.html',
  styleUrls: ['./tool-repairs-dashboard.component.scss']
})
export class ToolRepairsDashboardComponent implements OnInit, AfterViewInit {

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  ranges = {
    'All Dates': [moment().subtract(2, 'year').startOf('month'), moment()],
    'Today': [moment().subtract(0, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };

  selected: { startDate: Moment, endDate: Moment };

  show: boolean;
  loadingIndicator: boolean;
  isHistoryPanelOpen: boolean = false;


  @ViewChild('searchInput')
  searchInput: ElementRef;
  searchValue: string = "";

  @Input() isViewOnly: boolean;

  auditObj = new CoreAudit();
  emailObject: Email = new Email();

  columns: any[] = [];
  rows: JobCard[] = [];
  rowsCache: JobCard[] = [];

  jobCard: JobCard;
  jobCardDetail: JobCard;

  @ViewChild('jobCardDetailModal') jobCardDetailModal: ModalDirective;

  statuses: CoreJCStatus[] = [];

  customersCache: ArCustomer[] = [];
  selectedCustomerCode: string = '';
  selectedCustomerName: string = '';

  @ViewChild('customers') private customers;
  @ViewChild('customerSelector') private customerSelector;

  @ViewChild('dateTemplate') dateTemplate: TemplateRef<any>;
  @ViewChild('selectTemplate') selectTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate: TemplateRef<any>;
  @ViewChild('statusChangeTemplate') statusChangeTemplate: TemplateRef<any>;
  @ViewChild('customerTemplate') customerTemplate: TemplateRef<any>;
  @ViewChild('quoteTemplate') quoteTemplate: TemplateRef<any>;
  @ViewChild('invoiceTemplate') invoiceTemplate: TemplateRef<any>;
  @ViewChild('purchaseOrderTemplate') purchaseOrderTemplate: TemplateRef<any>;

  @ViewChild('statusListSelector') private statusListSelector;
  selectedStatus: string = "";
  currentStatus: string;
  smartsearch: string = "";

  invoiceNumber: string;

  // Properties for search functionality
  searchCustomerType: string = 'customer'; // Default to customer code
  searchCustomerText: string = '';
  searchCustomerResults: ArCustomer[] = [];
  searchCustomerResultSelected: ArCustomer;

  @ViewChild('searchByCustomerSelector') private searchByCustomerSelector;
  @ViewChild('customerSearchResultsSelector') private customerSearchResultsSelector;


  @ViewChild('quoteSearchModal') quoteSearchModal: ModalDirective;
  @Input() selectedQuote: string;
  showQuotePopup: boolean;

  @ViewChild('emailQuoteModal')
  emailQuoteModal: ModalDirective;
  showCustomerQuoteModal: boolean;

  searchQuoteType: string = 'customer'; // Default to customer code
  searchQuoteText: string = '';
  searchQuoteResults: QuoteList[] = [];
  searchQuoteResultSelected: QuoteList;
  quoteSearch: boolean;

  @ViewChild('searchByQuoteSelector')
  private searchByQuoteSelector;

  @ViewChild('QuoteSearchResultsSelector')
  private QuoteSearchResultsSelector;

  searchType: string;
  searchText: string;
  private selectUndefinedOptionValue: string = "Please Select";

  dateRows: JobCard[];

  @ViewChild('invoiceSearchModal')
  invoiceSearchModal: ModalDirective;

  @Input()
  selectedInvoice: string;
  showInvoicePopup: boolean;

  searchInvoiceType: string = 'customer'; // Default to customer code
  searchInvoiceText: string = '';
  searchInvoiceResults: SalesOrderList[] = [];
  searchInvoiceResultSelected: SalesOrderList;
  invoiceSearch: boolean;

  @ViewChild('searchByInvoiceSelector')
  private searchByInvoiceSelector;

  @ViewChild('InvoiceSearchResultsSelector')
  private InvoiceSearchResultsSelector;
  selectedSalesOrder: string;
  editJobCard: boolean;

  @ViewChild('supplierSelector') supplierSelector;
  @ViewChild('currentSupplier') currentSupplier: Supplier;
  @ViewChild('suppliers') private suppliers;
  suppliersCache: Supplier[] = [];
  selectedSupplier: Supplier;
  addselectedSupplier: Supplier;

  @ViewChild('salesPerson') private salesPerson;
  usersCache: SysproUser[] = [];
  selectedSalesPerson: User;

  jobCardStatusHistory: JobCardStatusHistory[];
  oldStatus: number; oldStatusId: number = null;

  @ViewChild('poTemplate')
  poTemplate: TemplateRef<any>;

  @ViewChild('purchaseOrderList')
  purchaseOrderList: TemplateRef<any>;

  @ViewChild('purchaseOrderListSelector')
  private purchaseOrderListSelector;

  private selectUndefinedPOOptionValue: string = "Please Select";
  showPurchaseOrderPopup: boolean;
  purchaseOrderSearch: boolean;

  @ViewChild('purchaseOrderSearchModal')
  purchaseOrderSearchModal: ModalDirective;

  @Input()
  selectedPurchaseOrder: string;

  selectedFilterStatus: number = 0;
  @ViewChild('statusFilter')
  private statusFilter;

  sysproUsers: SysproUser[];

  @ViewChild('operators') private operators;
  @ViewChild('operatorSelector') private operatorSelector;
  allOperators: SysproUser[] = [];
  selectedOperator: string;
  jobCardUsers: any;
  src: string;

  predefinedComments: any[] = [];
  selectedComment: string = '';
  customComment: any;
  selectedCompany: string;

  @ViewChild('emailModal')
  emailModal: ModalDirective;
    showCustomerEmailModal: boolean;
  selectedSupplierIndex: number | null = null;

  @ViewChild('podModal') podModal: ModalDirective;

  hasPODs: boolean;
  referenceList: BarcodeReference[] = [];
    WhatsappEnabled: boolean;
    WhatsappNumber: string;
    outboxDetail: any;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService) {}

  // #region Lifecycle Hooks
  ngOnInit() {
    this.selectedCompany = (this.application == "MaxArcusOnline" ? "M" : "F");

    let defaultOperator = new SysproUser();
    defaultOperator.sysproOperator = this.selectUndefinedOptionValue;
    defaultOperator.fullName = "";

    this.allOperators.push(defaultOperator);
    this.loadUsersByCompany();

    this.selected = {
      startDate: moment().subtract(0, 'year').startOf('year'),
      endDate: moment().subtract(0, 'month').endOf('month')
    };

    this.setData();
    this.loadData();
    this.loadPredefinedComments();

    //Check for wapp settings
    this.sysproService.GetUserTel(this.accountService.currentUser.userName).subscribe(x => this.onTelReceived(x), error => this.onOptinLoadFailed(error));

  }


  onTelReceived(x: string): void {
    console.log(x);
    this.WhatsappEnabled = true;
    this.WhatsappNumber = x;

  }
  onOptinLoadFailed(error: any): void {
    this.WhatsappEnabled = false;
  }
  onUserWappLoadSuccesful(x: OptIns): void {
    if (x) {
      //this.WhatsappEnabled = x.optedIn;
      this.WhatsappEnabled = true;
      this.WhatsappNumber = x.phoneNumber;
    }
  }

  ngAfterViewInit() {
    this.setData();
  }
  // #endregion

  // #region Data Setup and Loading
  private setData() {
    this.columns = [
      { prop: 'id', name: '#', width: 30, cellClass: "center" },
      { prop: 'company', name: 'C', width: 30, cellClass: "center" },
      { prop: 'dateMachineReceived', name: 'Received Date', width: 90, cellTemplate: this.dateTemplate, cellClass: "center" },
      { prop: 'jobCardNumber', name: 'Job Card #', width: 90, cellTemplate: this.selectTemplate, cellClass: "center" },
      { prop: 'binNumber', name: 'Bin', width: 40, cellClass: "center" },
      { prop: 'statusId', name: 'Status', width: 210, cellTemplate: this.statusTemplate },
      //{ prop: 'daysSinceStatusChanged', name: 'Days Since', width: 50, cellTemplate: this.statusChangeTemplate },
      { prop: 'customerCode', name: 'Customer', width: 200, cellTemplate: this.customerTemplate },
      { prop: 'machineDescription', name: 'Machine Description', width: 200 },
      { prop: 'quoteNumber', name: 'Quote Number', width: 120, cellTemplate: this.quoteTemplate },
      { prop: 'salesOrderNumber', name: 'SalesOrder Number', width: 120, cellTemplate: this.invoiceTemplate },
      { prop: 'purchaseOrderNumber', name: 'Purchase Order Number', width: 150, cellTemplate: this.poTemplate },
      { prop: 'assignedTechnician', name: 'Technician', width: 200 },
      { prop: 'customerContactInfo', name: 'Customer Contact Info', width: 250 },
      { prop: 'completionDate', name: 'Completion Date', width: 150, cellTemplate: this.dateTemplate },
      { prop: 'costOfRepair', name: 'Cost of Repair', width: 120, cellClass: "right" }
    ];
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.getStatuses();
    this.sysproService.getAllSuppliers().subscribe(
      x => this.onSupplierLoadSuccessful(x),
      error => this.onDataLoadFailed(error)
    );
    this.fowkesService.getJobCards().subscribe(
      x => this.onSearchSuccesful(x),
      error => {
        this.alertService.showStickyMessage('', 'No Job Cards found for your search', MessageSeverity.warn);
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      }
    );
  }

  loadUsersByCompany() {
    this.alertService.startLoadingMessage();
    this.sysproService.getSysproUserDetailListByCompany(this.selectedCompany).subscribe(
      (users) => {
        this.jobCardUsers = users;
        this.alertService.stopLoadingMessage();
      },
      (error) => {
        this.onDataLoadFailed(error);
      }
    );
  }

  onCompanyChange() {
    // Handle the event when the company is changed
    this.loadUsersByCompany();
  }

  selectSalesPerson() {
    const selected = this.jobCardUsers.find(
      operator => operator.sysproOperator === this.selectedOperator
    );

    if (selected) {
      this.jobCardDetail.salesPersonOperator = selected.sysproOperator;
      this.jobCardDetail.salesPersonName = selected.fullName;

      // Determine which email to use based on the selected company with a fallback
      this.jobCardDetail.salespersonEmail = selected.nextGenUser;
    }
  }
  // #endregion

  // #region Event Handlers and Data Processing
  onSearchSuccesful(jobCards: JobCard[]): void {
    if (this.statuses && this.statuses.length > 0) {
      jobCards.forEach(jobCard => {
        const status = this.statuses.find(s => s.statusId === jobCard.statusId);
        jobCard.statusName = status ? status.statusName : 'Unknown Status';
      });
    }
    jobCards.forEach(jobCard => {

      if (jobCard.lastStatusChangeDate) {
        const currentDate = new Date();
        const lastStatusChangeDate = new Date(jobCard.lastStatusChangeDate);
        jobCard.daysSinceStatusChanged = Math.floor((currentDate.getTime() - lastStatusChangeDate.getTime()) / (1000 * 60 * 60 * 24));

        // Set isHighlight to true if more than 7 days have passed since the last status change
        jobCard.isHighlight = jobCard.daysSinceStatusChanged > 7;
      } else {
        jobCard.isHighlight = false; // No status change, no highlight
      }

      if (jobCard.salesOrderNumber) {
        this.sysproService.getPurchaseOrderSearchByCriteria("SALESORDER", jobCard.salesOrderNumber)
          .subscribe(x => {
            jobCard.poNumbers = x;
            if (x && x.length > 0) {
              jobCard.purchaseOrderNumber = "Please Select";
            } else {
              jobCard.purchaseOrderNumber = '';
            }
          }, error => {
            console.error('Error loading purchase orders for sales order', error);
          });
      }
    });
    this.rows = jobCards;
    this.rowsCache = [...jobCards];
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  getRowClass(row: JobCard): { [key: string]: boolean } {
    return {
      'highlight-row': row.isHighlight
    };
  }


  getStatuses() {
    this.fowkesService.getJobCardStatuses()
      .subscribe((statuses: CoreJCStatus[]) => {
        this.statuses = statuses;
        this.selectedFilterStatus = 0;
      }, error => {
        console.error("Error fetching statuses: ", error);
      });
  }

  loadPredefinedComments() {

    this.fowkesService.getJobCardComments().subscribe(
      comments => this.predefinedComments = comments,
      error => this.alertService.showStickyMessage('Error', 'Failed to load comments', MessageSeverity.error)
    );

  }

  addCommentToJobCard() {
    const commentsToAdd = [];

    // Add the pre-defined comment if selected
    if (this.selectedComment) {
      commentsToAdd.push(this.selectedComment.toUpperCase());
      this.selectedComment = '';
    }

    // Add the custom comment if entered
    if (this.customComment) {
      commentsToAdd.push(this.customComment.toUpperCase());
      this.customComment = '';
    }

    if (commentsToAdd.length > 0) {
      this.jobCardDetail.comments += `\n${commentsToAdd.join('\n')}`;
    } else {
      this.alertService.showStickyMessage('No Comment', 'Please select a pre-defined comment or enter a custom comment.', MessageSeverity.warn);
    }
  }

  // #endregion

  // #region Modals and Popups
  addNewJobCard() {
    //Firt refresh
    this.refresh();

    this.editJobCard = false;
    this.jobCardDetail = new JobCard();
    this.jobCardDetail.jobCardNumber = this.generateJobCardNumber();
    this.jobCardDetail.branchCode = this.branchCode;
    this.jobCardDetail.dateMachineReceived = this.getTodayDate();
    this.jobCardDetail.referenceNumber = "";
    this.jobCardDetail.customerCode = "";
    this.jobCardDetail.customerName = "";
    this.jobCardDetail.machineDescription = "";
    this.jobCardDetail.statusId = 1;
    this.jobCardDetail.assignedTechnician = "";
    this.jobCardDetail.completionDate = null;
    this.jobCardDetail.costOfRepair = 0;
    this.jobCardDetail.customerContactInfo = "";
    this.jobCardDetail.accessories = "";
    this.jobCardDetail.accessoriesList = [];
    this.selectedCompany = this.application === 'MaxArcusOnline' ? 'M' : 'F';
    this.jobCardDetail.company = this.selectedCompany;
    this.selectedOperator = null;

    this.jobCardDetail.additionalSuppliers = [];

    this.selectedSupplier = null;

    if (!this.jobCardDetailModal) {
      setTimeout(() => {
        if (this.jobCardDetailModal) {
          this.jobCardDetailModal.show();
        }
      });
    }
    else {
      this.jobCardDetailModal.show();
    }
  }
  // #endregion

  viewJobCard(row: JobCard) {

    this.jobCardDetail = new JobCard();
    // Fetch the job card details based on jobCardNumber or id from the row
    this.fowkesService.getJobCardById(row.id).subscribe(
      (jobCard: JobCard) => {

        this.jobCardDetail = jobCard; // Assign the fetched job card to jobCardDetail
        this.editJobCard = true;


        // Load third-party suppliers if any
        this.fowkesService.getAdditionalSuppliers(this.jobCardDetail.id).subscribe(suppliers => {
          // Ensure no blank spaces in email or other fields
          this.jobCardDetail.additionalSuppliers = (suppliers || []).map(supplier => {
            return {
              ...supplier,
              supplierEmail: supplier.supplierEmail ? supplier.supplierEmail.trim() : '' // Trim whitespace or set to empty
            };
          });

          // Determine if repair is in-house or outsourced
          if (this.jobCardDetail.additionalSuppliers.length === 0) {
            // In-house repair
            this.selectedSupplier = new Supplier();
            this.selectedSupplier.supplier = "FOWKES";
            this.selectedSupplier.supplierName = "Fowkes (In House Repair)";
            this.jobCardDetail.assignedTechnician = this.selectedSupplier.supplier + ":" + this.selectedSupplier.supplierName;
            
          } else {
            // Outsourced repair to third-party suppliers
            this.selectedSupplier = null; // Clear the in-house selection
            this.jobCardDetail.assignedTechnician = "Outsourced Repair"; // No in-house technician assignment
          }
        });

        this.jobCardDetail.accessoriesList = [];
        this.jobCardDetail.accessoriesList = JSON.parse(jobCard.accessories || '[]') as string[];

        //Search and assign job card customer
        this.searchCustomerResults = null;

        // Call the service to search by criteria
        this.sysproService.getCustomerSearchByCriteria("Customer", this.jobCardDetail.customerCode).subscribe(
          (customers) => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.searchCustomerResults = customers;
            this.searchCustomerResultSelected = customers[0]},
          (error) => this.onDataLoadFailed(error)
        );

        this.jobCardDetail.customerContactInfo = jobCard.customerContactInfo;
        this.jobCardDetail.jobContactName = jobCard.jobContactName;
        this.jobCardDetail.jobContactEmail = jobCard.jobContactEmail;
        this.jobCardDetail.jobContactTelephone = jobCard.jobContactTelephone;

        this.selectedCompany = jobCard.company || (this.application === 'MaxArcusOnline' ? 'M' : 'F');

        this.selectedOperator = jobCard.salesPersonOperator;       

        if (this.jobCardDetail.purchaseOrderNumber) {
          this.sysproService.getPurchaseOrderSearchByCriteria("SALESORDER", this.jobCardDetail.salesOrderNumber)
            .subscribe(x => {
              this.jobCardDetail.poNumbers = x;  // Attach the PO numbers to the job card
              if (x && x.length > 0) {
                this.jobCardDetail.purchaseOrderNumber = "Please Select";
              } else {
                this.jobCardDetail.purchaseOrderNumber = ''; // Reset if no POs are found
              }
            }, error => {
              console.error('Error loading purchase orders for sales order', error);
            });
        }

        // Set the date in the calendar
        const today = new Date(this.jobCardDetail.dateMachineReceived);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');  // months are 0-based
        const day = String(today.getDate()).padStart(2, '0');

        // Set the dateMachineReceived to YYYY-MM-DD format
        this.jobCardDetail.dateMachineReceived = `${year}-${month}-${day}`;

        this.fowkesService.getJobCardStatusHistory(row.id).subscribe(
          (x: JobCardStatusHistory[]) => {
            // Filter to include entries with "status" updates or "Job Created" updateType and sort by ChangeDate in descending order
            this.jobCardStatusHistory = x
              .filter(history =>
                history.updateType &&
                (history.updateType.toLowerCase().includes('status') || history.updateType.toLowerCase() === 'job created')
              )
              .sort((a, b) => {
                return new Date(b.changeDate).getTime() - new Date(a.changeDate).getTime(); // Sort by ChangeDate in descending order
              });
          },
          (error) => {
            console.error('Failed to load job card status history', error);
          }
        );

        // Calculate days since the last status change if date exists
        if (this.jobCardDetail.lastStatusChangeDate) {
          const lastChangeDate = new Date(jobCard.lastStatusChangeDate);
          const currentDate = new Date();
          const timeDiff = currentDate.getTime() - lastChangeDate.getTime();
          this.jobCardDetail.daysSinceStatusChanged = Math.floor(timeDiff / (1000 * 3600 * 24));
        } else {
          this.jobCardDetail.daysSinceStatusChanged = null;
        }

        this.jobCardDetail.machineDescription = jobCard.machineDescription.toUpperCase();
        this.jobCardDetail.comments = jobCard.comments.toUpperCase();

        // Open the modal for editing the job card
        if (!this.jobCardDetailModal) {
          setTimeout(() => {
            if (this.jobCardDetailModal) {
              this.jobCardDetailModal.show();
            }
          });
        } else {
          this.jobCardDetailModal.show();
        }
      },
      (error) => {
        this.alertService.showStickyMessage('Load Error', 'Unable to load job card details', MessageSeverity.error, error);
      }
    );
  }



  toggleAccessory(accessory: string): void {
    if (!this.jobCardDetail.accessoriesList) {
      this.jobCardDetail.accessoriesList = [];
    }

    const index = this.jobCardDetail.accessoriesList.indexOf(accessory);

    if (index === -1) {
      // Add the accessory if it isn't already selected
      this.jobCardDetail.accessoriesList.push(accessory);
    } else {
      // Remove the accessory if it's already selected
      this.jobCardDetail.accessoriesList.splice(index, 1);
    }
  }


  // #region Customer Search and Set
  specificCustomerSearch() {
    if (this.searchCustomerType && this.searchCustomerText !== "") {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.searchCustomerResults = null;

      // Call the service to search by criteria
      this.sysproService.getCustomerSearchByCriteriaByCompany(this.searchCustomerType, this.searchCustomerText, this.selectedCompany).subscribe(
        (customers) => this.onSpecificCustomerSearchSuccesful(customers),
        (error) => this.onDataLoadFailed(error)
      );
    } else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on', MessageSeverity.warn);
    }
  }

  onSpecificCustomerSearchSuccesful(customers: ArCustomer[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchCustomerResults = customers;

    // If only one customer is returned, automatically select it
    if (customers.length === 1) {
      this.selectCustomer(customers[0]);
    }
  }

  viewCustomerSelected() {
    if (this.searchCustomerResultSelected) {
      this.selectCustomer(this.searchCustomerResultSelected);
    }
  }

  selectCustomer(customer: ArCustomer) {
    this.searchCustomerResultSelected = customer;
    this.jobCardDetail.customerCode = customer.customer;
    this.jobCardDetail.customerName = customer.customerName;
    this.jobCardDetail.jobContactName = customer.contact;
    this.jobCardDetail.jobContactEmail = customer.email;
    this.jobCardDetail.jobContactTelephone = customer.telephone;
    this.jobCardDetail.customerContactInfo = customer.contact || "" + customer.addTelephone || "";
  }
   // #endregion

  // Called when the user changes the status from the dropdown in the grid
  onStatusChange(row: JobCard) {
    this.editJobCard = true;

       //Update Job Card
    this.saveJobCard(row, true);
  }

  // Capture the old status when the user focuses on the status dropdown
  onStatusFocus(row: JobCard) {
    this.oldStatusId = row.statusId;  // Save the current status before the user changes it
  }

  setStatus() {
   
  }

  // #region Supplier Search and Set

  onSupplierLoadSuccessful(suppliers: Supplier[]) {
    this.suppliersCache = suppliers;

    // Refresh dropdown if necessary
    setTimeout(() => {
      if (this.supplierSelector) {
        this.supplierSelector.refresh();
      }
    }, 100);
  }

  setSupplier() {
    if (this.selectedSupplier) {
      // Check if the selected supplier is not "FOWKES"
      if (this.selectedSupplier.supplier !== "FOWKES") {
        // Create a new object for the selected supplier to avoid reference issues
        const newSupplier = {
          id: 0, // Indicates a new supplier (will be assigned in the database)
          jobCardId: this.jobCardDetail.id,
          supplierCode: this.selectedSupplier.supplier,
          supplierName: this.selectedSupplier.supplierName,
          supplierEmail: this.selectedSupplier.email.trim(),
          supplierTelephone: this.selectedSupplier.telephone,
          addedDate: new Date(),
          // New fields with initial values
          originalInvoice: '', // Placeholder, can be updated later if needed
          originalInvoiceDate: null, // Set to null initially
          supplierSpecificPo: '', // Placeholder for supplier-specific PO
          completedDate: null, // Set to null initially
          supplierStatus: '' // Placeholder, can be updated based on status
        };

        // Add the new supplier to the additionalSuppliers array
        this.jobCardDetail.additionalSuppliers.push(newSupplier);
        this.jobCardDetail.assignedTechnician = "Outsourced Repair";
      }

      // Reset the selected supplier dropdown for the next input
      this.selectedSupplier = null;
      this.jobCardDetail.supplierCode = "";
      this.jobCardDetail.supplierName = "";
      this.jobCardDetail.assignedTechnician = "";
      this.jobCardDetail.ptrNumber = "";
      this.jobCardDetail.supplierContact = "";
      this.jobCardDetail.supplierEmail = "";
      this.jobCardDetail.supplierTelephone = "";
      
    } else {
      // Set default details for in-house repairs
      this.jobCardDetail.supplierCode = "FOWKES";
      this.jobCardDetail.supplierName = "Fowkes (In House Repair)";
      this.jobCardDetail.assignedTechnician = "FOWKES : Fowkes (In House Repair)";
      this.jobCardDetail.supplierContact = "Mike";
      this.jobCardDetail.supplierEmail = "toolrepairs@fowkes.co.za";
      this.jobCardDetail.supplierTelephone = "";
    }
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  removeSupplier(index: number) {
    const supplier = this.jobCardDetail.additionalSuppliers[index];

    if (supplier.id) {
      // If the supplier exists in the database, call API to delete it
      this.fowkesService.deleteAdditionalSupplier(supplier.id).subscribe(
        () => {
          this.jobCardDetail.additionalSuppliers.splice(index, 1);
          this.alertService.showMessage('Success', 'Supplier removed successfully', MessageSeverity.success);
        },
        error => {
          this.alertService.showStickyMessage('Delete Error', 'Unable to remove supplier.', MessageSeverity.error, error);
        }
      );
    } else {
      // Just remove the new supplier from the local list
      this.jobCardDetail.additionalSuppliers.splice(index, 1);
    }
  }
  // #endregion


  // #region Quote
  addQuoteNumber() {

    this.showQuotePopup = true;
    this.quoteSearch = true;
    this.searchQuoteText = this.jobCardDetail.customerCode;

    this.specificQuoteSearch();

    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    if (!this.quoteSearchModal) {
      setTimeout(() => {
        if (this.quoteSearchModal) {
          this.quoteSearchModal.show();
        }
      });
    }
    else {
      this.quoteSearchModal.show();
    }
  }

  specificQuoteSearch() {
    if (this.searchQuoteType && this.searchQuoteText !== "") {
      this.searchQuoteResults = null;
      // Call the service to search by criteria
      this.sysproService.getQuoteNumberSearchByCriteriaByCompany(this.searchQuoteType, this.searchQuoteText, this.selectedCompany).subscribe(
        (quotes) => this.onSpecificQuoteSearchSuccesful(quotes),
        (error) => this.onDataLoadFailed(error)
      );
    } else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on', MessageSeverity.warn);
    }
  }

  onSpecificQuoteSearchSuccesful(quotes: any): void {
      this.searchQuoteResults = quotes;

      // If only one quote is returned, automatically select it
    if (quotes.length === 1) {
      this.searchQuoteResultSelected = quotes[0];
      this.selectQuote(this.searchQuoteResultSelected);
    }
  }

  viewQuoteSelected() {
    if (this.searchQuoteResultSelected) {
      this.selectQuote(this.searchQuoteResultSelected);
    }
  }

  selectQuote(quote: QuoteList) {
    this.selectedQuote = quote.quoteNumber;
    this.jobCardDetail.quoteNumber = this.selectedQuote;
    this.jobCardDetail.costOfRepair = quote.totalIncl;

    // Store quote details in session storage
    sessionStorage.setItem(localStorageActions.Customer, this.jobCardDetail.customerCode);
    sessionStorage.setItem(localStorageActions.QuoteNumber, this.selectedQuote);

    // Check for a converted reference (invoice) and fetch invoice if it exists
    if (quote.convertedRef) {
      this.jobCardDetail.salesOrderNumber = quote.convertedRef;
      this.searchInvoiceType = "salesorder";
      this.searchInvoiceText = this.jobCardDetail.salesOrderNumber;

      // Fetch the invoice associated with this quote
      this.sysproService.getSalesOrderSearchByCriteriaByCompany(this.searchInvoiceType, this.searchInvoiceText.trim(), this.selectedCompany)
        .subscribe(
          invoices => this.onSpecificInvoiceSearchSuccessful(invoices),
          error => this.onDataLoadFailed(error)
        );
    }
    //this.quoteSearchModalHide(); // Close the quote modal after selection
  }

  onSpecificInvoiceSearchSuccessful(invoices: SalesOrderList[]): void {
    if (invoices && invoices.length > 0) {
      const selectedInvoice = invoices[0];
      this.jobCardDetail.invoiceNumber = selectedInvoice.invoiceNumber;
      this.jobCardDetail.salesOrderNumber = selectedInvoice.salesOrderNumber;

      // Fetch associated purchase orders for the selected invoice
      this.SetPONumbers(selectedInvoice);
    } else {
      // Reset if no invoice is found
      this.jobCardDetail.invoiceNumber = '';
      this.jobCardDetail.salesOrderNumber = '';
    }
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  saveQuoteNumber(quote: string) {
    this.jobCardDetail.quoteNumber = quote;
    this.jobCardDetail.costOfRepair = this.searchQuoteResultSelected.totalIncl;

    if (this.searchQuoteResultSelected.convertedRef && this.searchQuoteResultSelected.convertedRef != "") {
      this.jobCardDetail.salesOrderNumber = this.searchQuoteResultSelected.convertedRef;

      this.searchInvoiceType = "salesorder";
      this.searchInvoiceText = this.jobCardDetail.salesOrderNumber;

      //Add invoice if found
      this.sysproService.getSalesOrderSearchByCriteriaByCompany(this.searchInvoiceType, this.searchInvoiceText.trim(), this.selectedCompany).subscribe(
        (invoices) => this.onSpecificQuoteInvoiceSearchSuccesful(invoices),
        (error) => this.onDataLoadFailed(error)
      );
    }
    this.quoteSearchModalHide();
  }
  // #endregion Quote

  selectInvoice(invoice: SalesOrderList) {
    this.selectedInvoice = invoice.invoiceNumber;
    this.selectedSalesOrder = invoice.salesOrderNumber;

    this.jobCardDetail.invoiceNumber = invoice.salesOrderNumber;
    this.jobCardDetail.salesOrderNumber = invoice.salesOrderNumber;

    if (invoice.poNumber != "" && invoice.poNumber != "NA") {
      invoice.poNumber = "Please Select";
      this.SetPONumbers(invoice);
    }
  }

  SetPONumbers(salesOrder: SalesOrderList) {
    this.sysproService.getPurchaseOrderSearchByCriteriaByCompany("SALESORDER", salesOrder.salesOrderNumber, this.selectedCompany).
      subscribe(x => this.POSearchSuccesful(salesOrder, x), error => { });
  }

  POSearchSuccesful(salesOrder: SalesOrderList, x: PurchaseOrderList[]): void {
    let poList = x;
    x.forEach((poNumber, index, x) => {
      (<any>poNumber).index = index + 1;
    });

    if (poList && poList.length > 0) {
      // Concatenate all the purchase order numbers into a single string
      salesOrder.poNumbers = x;
      this.jobCardDetail.poNumbers = x;
      //this.jobCardDetail.purchaseOrderNumber = x[0].purchaseOrder;
      this.jobCardDetail.purchaseOrderNumber = x.map(po => po.purchaseOrder).join(', ');
    } else {
      // If no POs are found, reset the purchase order number
      this.jobCardDetail.purchaseOrderNumber = '';
    }
  }

  quoteSearchModalHide() {
    // Remove grey-out effect from the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.remove('greyed-out');
    }
    this.showQuotePopup = false;
    this.quoteSearch = false;
    this.searchQuoteText = "";
    this.searchQuoteResultSelected = null;
    this.selectedQuote = "";
    if (this.quoteSearchModal) {
      this.quoteSearchModal.hide();
    }
  }

  previewQuote(jobCardDetail: JobCard) {

    this.jobCardDetail = jobCardDetail;

    const currentCompanyCode = this.configurations.applicationName === "MaxArcusOnline" ? "M" : "F";
    if (this.jobCardDetail.company != currentCompanyCode)
    {
      alert('You can only view documents within the current application.');
      return;
    }

    this.showQuotePopup = true;
    this.quoteSearch = false;
    this.selectedQuote = jobCardDetail.quoteNumber;

    if (sessionStorage.getItem(localStorageActions.Customer) != jobCardDetail.customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, jobCardDetail.customerCode);
    }
    if (sessionStorage.getItem(localStorageActions.QuoteNumber) != jobCardDetail.quoteNumber) {
      sessionStorage.setItem(localStorageActions.QuoteNumber, jobCardDetail.quoteNumber);
    }

    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    if (!this.quoteSearchModal) {
      setTimeout(() => {
        if (this.quoteSearchModal) {
          this.quoteSearchModal.show();
        }
      });
    }
    else {
      this.quoteSearchModal.show();
    }
  }

  onSearchFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `No results found, try adjusting your search criteria`,
      MessageSeverity.error);
  }

  saveJobCard(jobCardDetail: JobCard, row: boolean) {

    if (!this.jobCardValid(jobCardDetail)) { return; }

    jobCardDetail.referenceNumber = jobCardDetail.customerCode + "/" + jobCardDetail.jobCardNumber + "/" + jobCardDetail.ptrNumber + "/" + jobCardDetail.assignedTechnician;
    jobCardDetail.customerContactInfo = `${jobCardDetail.jobContactName || ''} ${jobCardDetail.jobContactEmail ? `| ${jobCardDetail.jobContactEmail}` : ''} ${jobCardDetail.jobContactTelephone ? `| ${jobCardDetail.jobContactTelephone}` : ''}`;
    jobCardDetail.modifiedBy = this.accountService.currentUser.email;
    jobCardDetail.machineDescription = jobCardDetail.machineDescription.toUpperCase();
    jobCardDetail.comments = jobCardDetail.comments.toUpperCase();

    if (!row) {

      jobCardDetail.accessories = JSON.stringify(jobCardDetail.accessoriesList || []);

      //Update the po number(s)
      if (jobCardDetail.poNumbers && jobCardDetail.poNumbers.length > 0) {
        // Concatenate all the purchase order numbers into a single string
        jobCardDetail.purchaseOrderNumber = jobCardDetail.poNumbers.map(po => po.poNumber).join(', ');
      } else {
        // If no POs are found, reset the purchase order number
        jobCardDetail.purchaseOrderNumber = '';
      }
    }
    else {
      if ((!jobCardDetail.accessories || jobCardDetail.accessories == "") && (jobCardDetail.accessoriesList && jobCardDetail.accessoriesList.length >= 1)) {
        jobCardDetail.accessories = JSON.stringify(jobCardDetail.accessoriesList || []);
      }
    }

    jobCardDetail.company = this.selectedCompany;
    this.alertService.startLoadingMessage('Saving Job Card...');
    this.loadingIndicator = true;

    // Call the service to save or update the job card
    if (this.editJobCard) {
      this.fowkesService.updateJobCard(jobCardDetail).subscribe(
        () => this.handleJobCardSaveSuccess(row, jobCardDetail),
        error => this.handleJobCardSaveError(error)
      );
    } else {
      this.fowkesService.createJobCard(jobCardDetail).subscribe(
        response => {
          this.editJobCard = true; // After creation, set editJobCard to true for future updates
          jobCardDetail.id = response.id;
          this.handleJobCardSaveSuccess(row, jobCardDetail);
        },
        error => this.handleJobCardSaveError(error)
      );
    }
  }

  handleJobCardSaveSuccess(row: boolean, jobCardDetail: JobCard) {
    // Check if the repair is in-house or with external suppliers
    if (jobCardDetail.additionalSuppliers && jobCardDetail.additionalSuppliers.length > 0) {
      let suppliersToUpdate = jobCardDetail.additionalSuppliers;
      let completedRequests = 0;

      // Iterate through each supplier and handle add or update logic
      suppliersToUpdate.forEach(supplierToUpdate => {
        supplierToUpdate.jobCardId = jobCardDetail.id;
        if (supplierToUpdate.id) {
          // Update existing supplier
          this.fowkesService.updateAdditionalSupplier(supplierToUpdate.id, supplierToUpdate).subscribe(
            response => this.checkIfAllSuppliersProcessed(++completedRequests, suppliersToUpdate.length, row, jobCardDetail),
            error => this.handleJobCardSaveError(error)
          );
        } else {
          // Add new supplier
          this.fowkesService.addAdditionalSupplier(supplierToUpdate).subscribe(
            response => this.checkIfAllSuppliersProcessed(++completedRequests, suppliersToUpdate.length, row, jobCardDetail),
            error => this.handleJobCardSaveError(error)
          );
        }
      });
    } else {
      // Directly finalize the save if no additional suppliers
      this.finalizeJobCardSave(row, jobCardDetail);
    }
  }

  // Check if all supplier updates/adds have completed
  checkIfAllSuppliersProcessed(completedRequests: number, totalRequests: number, row: boolean, jobCardDetail: JobCard) {
    if (completedRequests === totalRequests) {
      this.finalizeJobCardSave(row, jobCardDetail);
    }
  }

  finalizeJobCardSave(row: boolean, jobCardDetail: JobCard): any {

    if (!row) {
      this.jobCardDetailModalHide();
      this.loadData();
    } else {
      this.jobCardDetail = jobCardDetail;
          }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  handleJobCardSaveError(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('Save Error', 'An error occurred while saving the Job Card.', MessageSeverity.error, error);
  }

  openInvoiceSearchModal(index: number) {
    this.showInvoicePopup = true;
    this.invoiceSearch = true;
    this.searchInvoiceType = "salesorder";
    this.searchInvoiceText = "";
    this.specificInvoiceSearch();

    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    if (!this.invoiceSearchModal) {
      setTimeout(() => {
        if (this.invoiceSearchModal) {
          this.invoiceSearchModal.show();
        }
      });
    }
    else {
      this.invoiceSearchModal.show();
    }
  }

  specificInvoiceSearch() {
    if (this.searchInvoiceText && this.searchInvoiceText !== "") {
      this.searchInvoiceResults = null;
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      // Call the service to search by criteria
      this.sysproService.getSalesOrderSearchByCriteriaByCompany(this.searchInvoiceType, this.searchInvoiceText.trim(),  this.selectedCompany).subscribe(
        (invoices) => this.onSpecificInvoiceSearchSuccesful(invoices),
        (error) => this.onDataLoadFailed(error)
      );
    } else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on', MessageSeverity.warn);
    }
  }

  onSpecificQuoteInvoiceSearchSuccesful(invoices: SalesOrderList[]): void {
    this.searchInvoiceResults = invoices;

    // If only one invoice is returned, automatically select it
    if (invoices.length === 1) {
      this.searchInvoiceResultSelected = invoices[0];
      this.selectInvoice(this.searchInvoiceResultSelected);
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  onSpecificInvoiceSearchSuccesful(invoices: SalesOrderList[]): void {
    this.searchInvoiceResults = invoices;

    // If only one invoice is returned, automatically select it
    if (invoices.length === 1) {
      this.searchInvoiceResultSelected = invoices[0];
      this.selectSupplierInvoice(this.searchInvoiceResultSelected);
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }


  selectSupplierInvoice(invoice: SalesOrderList) {
    this.selectedInvoice = invoice.invoiceNumber;
    this.selectedSalesOrder = invoice.salesOrderNumber;

    this.jobCardDetail.originalInvoice = invoice.salesOrderNumber;

    //if (invoice.poNumber != "" && invoice.poNumber != "NA") {
    //  invoice.poNumber = "Please Select";
    //  this.SetPONumbers(invoice);
    //}

    this.sysproService.getPODByInvoice(this.selectedInvoice)
      .subscribe(
        response => this.onBarcodesLoadSuccessful(response),
        error => {
          this.hasPODs = false;
          this.referenceList = [];
          this.jobCardDetail.originalInvoiceHasPODs = false;
          this.jobCardDetail.originalInvoiceDetails = "";
        }
      );
  }



  onBarcodesLoadSuccessful(scannedDocs: BarcodeReference[]) {
    if (scannedDocs && scannedDocs.length > 0) {
      this.hasPODs = true;
      this.referenceList = scannedDocs;
      this.jobCardDetail.originalInvoiceHasPODs = true;
      this.jobCardDetail.originalInvoiceDetails = scannedDocs.map(pod => pod.fileName).join(', ');
    } else {
      this.hasPODs = false;
      this.referenceList = [];
      this.jobCardDetail.originalInvoiceHasPODs = false;
      this.jobCardDetail.originalInvoiceDetails = "";
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  podModalShow() {

    if (this.jobCardDetail.originalInvoice) {

      // Add grey-out effect to the Job Card modal
      //const modalContent = document.querySelector('.job-card-modal .modal-content');
      //if (modalContent) {
      //  modalContent.classList.add('greyed-out');
      //}

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sysproService.getPODByInvoice(this.jobCardDetail.originalInvoice)
        .subscribe(
          response => this.onBarcodesLoadSuccessful(response),
          error => {
            this.hasPODs = false;
            this.referenceList = [];
            this.jobCardDetail.originalInvoiceHasPODs = false;
            this.jobCardDetail.originalInvoiceDetails = "";
          }
        );


      if (!this.podModal) {
        setTimeout(() => {
          if (this.podModal) {
            this.podModal.show();
          }
        });
      }
      else {
        this.podModal.show();
      }
    }
  }

  podModalHide() {
    this.podModal.hide();
  }



  viewPOD(row: BarcodeReference) {
    this.sysproService.getFileStream(row.fileName, row.fileNumber).subscribe(x => this.onViewPODSuccessful(x, row.fileName), error => this.onDataLoadFailed(error));
  }

  onViewPODSuccessful(x: Blob, fileName: string): void {
    var test = x;
    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //  window.navigator.msSaveOrOpenBlob(newBlob);
    //  return;
    //}

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
    console.log(test);
  }



  saveSupplierInvoiceNumber(invoiceNumber: string, invoiceDate: Date) {
    this.jobCardDetail.originalInvoice = invoiceNumber;
    this.jobCardDetail.originalInvoiceDate = invoiceDate;

    console.log(this.jobCardDetail);



    this.invoiceSearchModalHide();
  }




  viewInvoiceSelected() {
    if (this.searchInvoiceResultSelected) {
      this.selectInvoice(this.searchInvoiceResultSelected);
    }
  }



  viewSupplierInvoiceSelected() {
    if (this.searchInvoiceResultSelected) {
      this.selectSupplierInvoice(this.searchInvoiceResultSelected);
    }
  }

  saveInvoiceNumber(invoiceNumber: string, invoiceDate: Date) {
    this.jobCardDetail.originalInvoice = invoiceNumber;
    this.jobCardDetail.originalInvoiceDate = invoiceDate;
   
    this.invoiceSearchModalHide();
  }


  previewSupplierInvoice(jobCardDetail: JobCard) {

    this.jobCardDetail = jobCardDetail;

    if (!this.isJobCardCompanyMatch()) {
      alert('You can only view invoices within the current application.');
      return;
    }
    this.showInvoicePopup = true;
    this.invoiceSearch = true;
    this.selectedInvoice = this.jobCardDetail.originalInvoice;
    this.selectedSalesOrder = this.jobCardDetail.originalInvoice;


    this.searchInvoiceType = "salesorder";
    this.searchInvoiceText = this.selectedSalesOrder
    this.specificInvoiceSearch();


    if (sessionStorage.getItem(localStorageActions.Customer) != jobCardDetail.customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, jobCardDetail.customerCode);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != this.selectedSalesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.selectedSalesOrder);
    }
    if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != this.selectedInvoice) {
      sessionStorage.setItem(localStorageActions.InvoiceNumber, this.selectedInvoice);
    }
      

      // Add grey-out effect to the Job Card modal
      const modalContent = document.querySelector('.job-card-modal .modal-content');
      if (modalContent) {
        modalContent.classList.add('greyed-out');
      }

      if (!this.invoiceSearchModal) {
        setTimeout(() => {
          if (this.invoiceSearchModal) {
            this.invoiceSearchModal.show();
          }
        });
      }
      else {
        this.invoiceSearchModal.show();
      }
  }

  previewInvoice(jobCardDetail: JobCard) {

    this.jobCardDetail = jobCardDetail;

    const currentCompanyCode = this.configurations.applicationName === "MaxArcusOnline" ? "M" : "F";
    if (this.jobCardDetail.company != currentCompanyCode) {
      alert('You can only view documents within the current application.');
      return;
    }

    this.showInvoicePopup = true;
    this.invoiceSearch = false;

    this.selectedInvoice = jobCardDetail.invoiceNumber;
    this.selectedSalesOrder = jobCardDetail.salesOrderNumber;


    if (sessionStorage.getItem(localStorageActions.Customer) != jobCardDetail.customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, jobCardDetail.customerCode);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != this.selectedSalesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.selectedSalesOrder);
    }
    if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != this.selectedInvoice) {
      sessionStorage.setItem(localStorageActions.InvoiceNumber, this.selectedInvoice);
    }

    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    if (!this.invoiceSearchModal) {
      setTimeout(() => {
        if (this.invoiceSearchModal) {
          this.invoiceSearchModal.show();
        }
      });
    }
    else {
      this.invoiceSearchModal.show();
    }
  }

  invoiceSearchModalHide() {
    // Remove grey-out effect from the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.remove('greyed-out');
    }
    this.showInvoicePopup = false;
    this.invoiceSearch = false;
    this.searchInvoiceText = "";
    this.searchInvoiceResultSelected = null;
    this.selectedInvoice = "";
    this.selectedSalesOrder = "";
    this.selectedSupplierIndex = null;
    this.hasPODs = false;
    this.referenceList = [];

    if (this.invoiceSearchModal) {
      this.invoiceSearchModal.hide();
    }


  }

  previewPurchaseOrderDetail(jobCardDetail: JobCard, po: PurchaseOrderList) {
    this.showPurchaseOrderPopup = true;
    this.purchaseOrderSearch = false;

    this.jobCardDetail = jobCardDetail;
    const currentCompanyCode = this.configurations.applicationName === "MaxArcusOnline" ? "M" : "F";
    if (this.jobCardDetail.company != currentCompanyCode) {
      alert('You can only view documents within the current application.');
      return;
    }

    this.selectedInvoice = jobCardDetail.invoiceNumber;
    this.selectedSalesOrder = jobCardDetail.salesOrderNumber;

    if (po && po.purchaseOrder) {

      this.selectedPurchaseOrder = po.purchaseOrder;
    }
    else {
      this.selectedPurchaseOrder = this.jobCardDetail.purchaseOrderNumber;
    }

    if (sessionStorage.getItem(localStorageActions.Customer) != jobCardDetail.customerCode) {
      sessionStorage.setItem(localStorageActions.Customer, jobCardDetail.customerCode);
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != this.selectedSalesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, this.selectedSalesOrder);
    }
    if (sessionStorage.getItem(localStorageActions.InvoiceNumber) != this.selectedInvoice) {
      sessionStorage.setItem(localStorageActions.InvoiceNumber, this.selectedInvoice);
    }
    if (sessionStorage.getItem(localStorageActions.PurchaseOrder) != this.selectedPurchaseOrder) {
      sessionStorage.setItem(localStorageActions.PurchaseOrder, this.selectedPurchaseOrder);
    }

    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    if (!this.purchaseOrderSearchModal) {
      setTimeout(() => {
        if (this.purchaseOrderSearchModal) {
          this.purchaseOrderSearchModal.show();
        }
      });
    }
    else {
      this.purchaseOrderSearchModal.show();
    }
  }

  purchaseOrderSearchModalHide() {
    // Remove grey-out effect from the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.remove('greyed-out');
    }
    this.showPurchaseOrderPopup = false;
    this.purchaseOrderSearch = false;
    this.selectedInvoice = "";
    this.selectedSalesOrder = "";
    this.selectedPurchaseOrder = "";

    if (this.purchaseOrderSearchModal) {
      this.purchaseOrderSearchModal.hide();
    }
  }

  jobCardDetailModalHide() {
    this.jobCardDetail = null;
    this.searchCustomerText = "";
    this.searchCustomerResults = null;
    this.searchCustomerResultSelected = null;
    this.jobCardStatusHistory = null;
    this.isHistoryPanelOpen = false;

    if (this.jobCardDetailModal) {
      this.jobCardDetailModal.hide();
    }
  }

  // #region Customer Documents
  onCustomerEmailLoadSuccessful() {
    if (!this.jobCardValid(this.jobCardDetail)) {
      this.alertService.showMessage('Invalid Job Card', 'Please complete all required fields before generating the PDF.', MessageSeverity.warn);
      return;
    }
    //First make sure job card is saved
    this.saveJobCard(this.jobCardDetail, true);

    // Add grey-out effect to the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.add('greyed-out');
    }

    this.alertService.startLoadingMessage("Loading Email...");
    this.loadingIndicator = true;
    this.emailObject.RecepientName = this.jobCardDetail.jobContactName;
    this.emailObject.RecepientEmail = this.jobCardDetail.jobContactEmail;//this.purchaseOrder.buyerEmail;
    this.emailObject.CC = (this.jobCardDetail.salespersonEmail ? this.jobCardDetail.salespersonEmail : "");
    this.emailObject.BCC = this.accountService.currentUser.email;
    this.emailObject.Subject = "Power Tool Repairs - Job Card Reference : " + this.jobCardDetail.jobCardNumber;
    this.emailObject.SenderName = this.accountService.currentUser.fullName;
    this.emailObject.SenderEmail = this.accountService.currentUser.email;
    this.emailObject.SenderBranch = this.branchCode;
    this.emailObject.SenderCompany = this.jobCardDetail.company;
    this.emailObject.Attachment = this.jobCardDetail.jobCardNumber;
    this.emailObject.EmailType = "JobCard_Customer";
    this.emailObject.Bod = "Please find attached document that can be viewed with any standard PDF reader. ";

    this.showCustomerEmailModal = true;
    if (!this.emailModal) {
      setTimeout(() => {
        if (this.emailModal) {
          this.emailModal.show();
        }
      });
    }
    else {
      this.emailModal.show();
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  getCustomerPDF(): void {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.saveJobCard(this.jobCardDetail, true); // Ensure job card is saved

    this.fowkesService.getJobCardCustomerPDF(this.jobCardDetail.jobCardNumber, this.jobCardDetail.company, true)
      .subscribe(
        x => {
          this.onCustomerFileSuccessful(x, false, this.jobCardDetail.jobCardNumber);
          this.updateHistoryForPDFGeneration(this.jobCardDetail, 'Customer');
        },
        error => this.onDataLoadFailed(error)
      );
  }

  onCustomerFileSuccessful(x: Blob, preview: boolean, jobCardNumber: string): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    if (!preview) {
      console.log(x);

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = jobCardNumber + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  emailCustomerPDF() {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.fowkesService.getJobCardCustomerPDF(this.jobCardDetail.jobCardNumber, this.jobCardDetail.company, false).subscribe(x => this.emailCustomerJobCard(), error => this.onDataLoadFailed(error));

  }

  private emailCustomerJobCard() {

    this.updateHistoryForEmailSent(this.jobCardDetail, this.emailObject.Subject, this.emailObject.RecepientEmail);
    this.fowkesService.sendjobCardEmail(this.emailObject).subscribe(x => this.onEmailSentSuccessful(x), error => this.onDataLoadFailed(error));

  }

  onEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);

    console.log(x);


    this.emailModalHide();
  }

  emailModalHide() {
    // Remove grey-out effect from the Job Card modal
    const modalContent = document.querySelector('.job-card-modal .modal-content');
    if (modalContent) {
      modalContent.classList.remove('greyed-out');
    }

    this.showCustomerEmailModal = false;

    this.emailObject = new Email();
   
    if (this.emailModal) {
      this.emailModal.hide();
    }
  }

   // #endregion Customer Documents
  onQuoteEmailLoadSuccessful() {

    if (this.selectedQuote) {
      const quoteNumber = this.selectedQuote;

      this.alertService.startLoadingMessage("Loading Email...");
      this.loadingIndicator = true;
      this.emailObject.RecepientName = this.jobCardDetail.jobContactName;
      this.emailObject.RecepientEmail = this.jobCardDetail.jobContactEmail;//this.purchaseOrder.buyerEmail;
      this.emailObject.CC = (this.jobCardDetail.salespersonEmail ? this.jobCardDetail.salespersonEmail : "");
      this.emailObject.BCC = this.accountService.currentUser.email;
      this.emailObject.Subject = "Power Tool Repairs - Job Card Reference : " + this.jobCardDetail.jobCardNumber;
      this.emailObject.SenderName = this.accountService.currentUser.fullName;
      this.emailObject.SenderEmail = this.accountService.currentUser.email;
      this.emailObject.SenderBranch = this.branchCode;
      this.emailObject.SenderCompany = this.jobCardDetail.company;
      this.emailObject.Attachment = quoteNumber;
      this.emailObject.Bod = "Thank you for allowing us to submit our quotation. Please find attached PDF that can be viewed with any standard PDF reader. If you decide to accept please reference this quote number.";


      this.reportService.getQuotePDF(quoteNumber, this.application).subscribe(x => {
        this.emailQuoteModal.show(),
          this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
      }, error => this.onDataLoadFailed(error));

    }
  }

  viewQuotePDF() {
    this.reportService.getFileStream(this.selectedQuote).subscribe(x => this.onQuoteFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  sendQuoteWappMessage() {
    this.outboxDetail = new Outbox();

    this.outboxDetail.toPhoneNumber = this.WhatsappNumber;
    this.outboxDetail.content = "Template";
    this.outboxDetail.headerMediaFileId = this.selectedQuote;
    this.outboxDetail.nextGenUser = this.accountService.currentUser.userName;

    this.sysproService.sendTemplateWithFile(this.outboxDetail.headerMediaFileId, "QUOTE", this.outboxDetail.toPhoneNumber, this.outboxDetail.nextGenUser,
      this.outboxDetail.headerMediaFileId).subscribe(x => this.onWhatsappSentSuccesful(x), error => this.WhatsappSentFailed(error));
  }

  WhatsappSentFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Error', `Something went wrong: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  onWhatsappSentSuccesful(x: any): void {
    console.log(x);
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showStickyMessage('', `"${x.message}"`, MessageSeverity.info);
    this.emailQuoteModal.hide();
  }

  onMessageSentSuccesful(): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Message sent successfully`, MessageSeverity.success);
    this.emailQuoteModal.hide();
  }

  previewQuotePDF() {
    this.reportService.getFileStream(this.selectedQuote).subscribe(x => this.onQuoteFileSuccessful(x, true), error => this.onDataLoadFailed(error));
  }

  emailQuotePDF() {
    if (this.emailObject.RecepientEmail && this.emailObject.RecepientEmail.trim().length >= 1) {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      this.sysproService.sendEmail(this.emailObject).subscribe(x => this.onQuoteEmailSentSuccessful(x), error => this.onDataLoadFailed(error));
    }
    else {
      this.alertService.showMessage('Warning', `Remeber to add email address for recepient`, MessageSeverity.warn);
    }

  }

  onQuoteEmailSentSuccessful(x: Email): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Email sent successfully`, MessageSeverity.success);

    //Update status to " Quote Sent to Customer "
    //var row = this.jobCardDetail;
    //row.statusId = 3;
    //row.statusName = "Quote Sent To Customer";
    //row.lastStatusChangeDate = new Date;
    //row.daysSinceStatusChanged = 0;
    //this.onStatusChange(row);
    this.emailQuoteModal.hide();
  }

  getQuotePDF(x: Blob, quoteNumber: string): void {
    this.reportService.getFileStream(quoteNumber).subscribe(x => this.onQuoteFileSuccessful(x, false), error => this.onDataLoadFailed(error));
  }

  onQuoteFileSuccessful(x: Blob, preview: boolean): void {
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    //const fileUrl = URL.createObjectURL(newBlob);
    //window.location.href = fileUrl;

    if (!preview) {
    
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;    

        link.download = this.selectedQuote + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  // #region Customer Quotes Email
  

  // #endregion Customer Quotes Email
  getSupplierPDF(row: JobCardSuppliers): void {
    if (!this.jobCardValid(this.jobCardDetail)) {
      this.alertService.showMessage('Invalid Job Card', 'Please complete all required fields before generating the PDF.', MessageSeverity.warn);
      return;
    }

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.saveJobCard(this.jobCardDetail, true); // Ensure job card is saved

    // Delay for 2 seconds before attempting to generate the PDF
    setTimeout(() => {
      this.fowkesService.getJobCardSupplierPDF(this.jobCardDetail.jobCardNumber, this.jobCardDetail.company, row.supplierCode)
        .subscribe(
          x => {
            this.onSupplierFileSuccessful(x, false, this.jobCardDetail.jobCardNumber, row.supplierCode);
            this.updateHistoryForPDFGeneration(this.jobCardDetail, 'Supplier');
          },
          error => this.onDataLoadFailed(error)
        );
    }, 2000); // 2-second delay
  }


  saveJobCardSupplier(row: JobCardSuppliers): void {
    if (!this.jobCardValid(this.jobCardDetail)) {
      this.alertService.showMessage('Invalid Job Card', 'Please complete all required fields before generating the PDF.', MessageSeverity.warn);
      return;
    }

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.saveJobCard(this.jobCardDetail, true); // Ensure job card is saved
    
   
  }



  onSupplierFileSuccessful(x: Blob, preview: boolean, jobCardNumber: string, supplierCode: string): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    if (!preview) {
      console.log(x);

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = jobCardNumber +  "_" + supplierCode + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }


  getMasterPDF(jobCard: JobCard): void {
    if (!this.jobCardValid(this.jobCardDetail)) {
      this.alertService.showMessage('Invalid Job Card', 'Please complete all required fields before generating the PDF.', MessageSeverity.warn);
      return;
    }
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.saveJobCard(this.jobCardDetail, true); // Ensure job card is saved

    var quoteNumber = "";
    if (!jobCard.quoteNumber || jobCard.quoteNumber == "") {
      quoteNumber = "none";
    }
    else {
      quoteNumber = jobCard.quoteNumber;
    }
      
   
    // Delay for 2 seconds before attempting to generate the PDF
    setTimeout(() => {
      this.fowkesService.getJobCardMasterPDF(this.jobCardDetail.jobCardNumber, this.jobCardDetail.company, quoteNumber)
        .subscribe(
          x => {
            this.onMasterFileSuccessful(x, false, this.jobCardDetail.jobCardNumber);
            this.updateHistoryForPDFGeneration(this.jobCardDetail, 'Master');
          },
          error => this.onDataLoadFailed(error)
        );

    }, 2000); // 2-second delay
  }

 



  onMasterFileSuccessful(x: Blob, preview: boolean, jobCardNumber: string): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    var test = x;

    const url = window.URL.createObjectURL(test);
    var newBlob = new Blob([x], { type: "application/pdf" });

    this.src = url;
    if (!preview) {
      console.log(x);

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement('a');
      link.href = data;
      link.download = jobCardNumber + "_Master.pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }
    else {
      //this.pdfModal.show();
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);

      setTimeout(() => {
        iframe.contentWindow.print();
      }, 1000);
    }
  }

  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
        "Id",
        "Received Date",
        "Job Card Number",
        "PTR Number",
        "Bin",
        "Status",
        "Cust",
        "Name",
        "Machine Description",
        "Quote Number",
        "Invoice Number",
        "Purchase Order Number",
        "Technician",
        "Customer Contact Info",
        "Completion Date",
        "Cost of Repair"
      ]
    };

    var filename = "JobCards";
    var selectRows = [...this.rows];
    var exportRows = [];

    for (var row of selectRows) {
      let dateReceived = row.dateMachineReceived ? new Date(row.dateMachineReceived).toISOString().split('T')[0] : '';
      let completionDate = row.completionDate ? new Date(row.completionDate).toISOString().split('T')[0] : '';

      exportRows.push({
        Id: row.id,
        "Received Date": dateReceived,
        "Job Card Number": row.jobCardNumber,
        "PTR Number": row.ptrNumber,
        "Bin": row.binNumber,
        "Status": row.statusName, 
        "Cust": row.customerCode,
        "Name": row.customerName,
        "Machine Description": row.machineDescription,
        "Quote Number": row.quoteNumber,
        "Invoice Number": row.invoiceNumber,
        "Purchase Order Number": row.purchaseOrderNumber,
        "Technician": row.assignedTechnician,
        "Customer Contact Info": row.customerContactInfo,
        "Completion Date": completionDate,
        "Cost of Repair": row.costOfRepair
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }

  // #region audit trail
  updateHistoryForPDFGeneration(jobCardDetail: JobCard, documentType: string): void {
    const historyEntry = {
      id: 0,
      jobCardId: jobCardDetail.id,
      updateType: `Generated ${documentType} PDF`,
      newStatus: jobCardDetail.statusName,
      changedBy: this.accountService.currentUser.userName,
      changeDate: new Date(),
      notes: `<br>${documentType}</br> PDF generated for Job Card ${jobCardDetail.jobCardNumber}`
    };

    this.fowkesService.addJobCardHistory(historyEntry).subscribe(
      () => console.log(`${documentType} PDF generation recorded in history.`),
      (error) => console.error('Error updating history for PDF generation', error)
    );
  }

  updateHistoryForEmailSent(jobCardDetail: JobCard, documentType: string, emailTo: string): void {
    const historyEntry = {
      id: 0,
      jobCardId: jobCardDetail.id,
      updateType: `Emailed Customer Receipt`,
      newStatus: null,
      changedBy: this.accountService.currentUser.userName,
      changeDate: new Date(),
      notes: `Customer Receipt Emailed to <br>${emailTo}</br>`
    };

    this.fowkesService.addJobCardHistory(historyEntry).subscribe(
      () => console.log(`${documentType} email sent recorded in history.`),
      (error) => console.error('Error updating history for PDF generation', error)
    );
  }

  updateHistoryForStatusChange(jobCardDetail: JobCard, newStatus: string): void {
    const historyEntry = {
      id: 0,
      jobCardId: jobCardDetail.id,
      updateType: `Status Change`,
      newStatus: newStatus,
      changedBy: this.accountService.currentUser.userName,
      changeDate: new Date(),
      notes: `Status updated to '${newStatus}' for Job Card ${jobCardDetail.jobCardNumber} by ${this.accountService.currentUser.userName}.`
    };

    this.fowkesService.addJobCardHistory(historyEntry).subscribe(
      () => console.log(`Status change to '${newStatus}' recorded in history.`),
      (error) => console.error('Error updating history for status change', error)
    );
  }
  // endregion audit trail

  // #region Working functions
  get branchCode(): string {
    return this.configurations.branchCode;
  }

  get application(): string {
    return this.configurations.applicationName;
  }


  isJobCardCompanyMatch(): boolean {
    const currentCompanyCode = this.configurations.applicationName === "MaxArcusOnline" ? "M" : "F";
    return currentCompanyCode === this.selectedCompany;
  }


  isRowCompanyMatch(row: JobCard): boolean {
    const currentCompanyCode = this.configurations.applicationName === "MaxArcusOnline" ? "M" : "F";
    return currentCompanyCode === row.company;
  }

  toggleHistoryPanel() {
    this.isHistoryPanelOpen = !this.isHistoryPanelOpen;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  generateJobCardNumber(): string {
    const prefix = 'JC';
    let newNumber = 1;

    // Check if there are existing job cards in the grid cache
    if (this.rowsCache && this.rowsCache.length > 0) {
      // Sort by jobCardNumber and get the last entry
      const lastJobCard = this.rowsCache
        .sort((a, b) => parseInt(a.jobCardNumber.replace(prefix, ''), 10) - parseInt(b.jobCardNumber.replace(prefix, ''), 10))
        .pop();

      // Extract the numeric part from the last job card number and increment it
      if (lastJobCard && lastJobCard.jobCardNumber) {
        const lastNumber = parseInt(lastJobCard.jobCardNumber.replace(prefix, ''), 10);
        newNumber = lastNumber + 1;
      }
    }

    // Format the new number with leading zeros (e.g., '000001')
    const formattedNumber = newNumber.toString().padStart(6, '0');

    // Combine the prefix and the formatted number
    return `${prefix}${formattedNumber}`;
  }

  getTodayDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  jobCardValid(jobCardDetail: JobCard) {
    // Check if the job card details are valid before saving
    if (!jobCardDetail) {
      this.alertService.showStickyMessage('Error', 'No job card details provided.', MessageSeverity.error);
      return false;
    }

    let missingFields = [];

    if (!jobCardDetail.jobCardNumber || jobCardDetail.jobCardNumber === 'JC') {
      missingFields.push('Job Card Number');
    }

    if (!jobCardDetail.salesPersonOperator) {
      missingFields.push('Sales Person');
      return;
    }

    if (!jobCardDetail.customerCode) {
      missingFields.push('Customer Code');
    }

    if (!jobCardDetail.machineDescription) {
      missingFields.push('Machine Description');
    }

    if (!jobCardDetail.dateMachineReceived) {
      missingFields.push('Date Machine Received');
    }

    if (!jobCardDetail.jobContactName) {
      missingFields.push('Job Contact Name');
    }

    // If there are missing fields, show a specific error message
    if (missingFields.length > 0) {
      const message = `Please fill in the following fields: ${missingFields.join(', ')}`;
      this.alertService.showStickyMessage('Invalid Data', message, MessageSeverity.warn);
      return false;
    }
    else {
      return true;
    }

  }

  refresh() {
    this.selected = {
      startDate: moment().subtract(0, 'year').startOf('year'),
      endDate: moment().subtract(0, 'month').endOf('month')
    };
    this.loadData();
  }

  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  filterChanged() {
    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    // Filter rowsCache by the date range
    this.dateRows = this.rowsCache.filter(d => {
      const createdDate = new Date(d.createdDate);
      return createdDate >= startD && createdDate <= endD;
    });

    this.applySmartSearch();
  }

  // Smart Search filter
  applySmartSearch() {
    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    // Filter rowsCache by the date range
    this.dateRows = this.rowsCache.filter(d => {
      const createdDate = new Date(d.createdDate);
      return createdDate >= startD && createdDate <= endD;
    });

    let value = this.smartsearch.trim().toLowerCase();

    // Step 3: Filter dateRows based on the smart search
    let filteredRows = this.dateRows.filter(r => {
      return Utilities.searchArray(value,
        false, r.machineDescription, r.binNumber, r.quoteNumber, r.customerCode, r.customerName, r.customerContactInfo, r.invoiceNumber, r.jobCardNumber, r.assignedTechnician, r.salesPersonName, r.salespersonEmail);
    });

    // Step 4: Filter based on selected status if one is chosen
    if (this.selectedFilterStatus && this.selectedFilterStatus != 0) {
      filteredRows = filteredRows.filter(r => r.statusId === Number(this.selectedFilterStatus));
    }

    // Update the rows to display based on all filters
    this.rows = filteredRows;
  }

  reset() {
    this.jobCardDetailModalHide();
    this.loadData();
  }

  onSearchChanged(e: any) {
    this.applySmartSearch();
  }
  // #endregion Working functions

}

